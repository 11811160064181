import { Button, DataTable, FilterBar, Flex, TextEllipsis, TextInput, Tooltip } from '@dynatrace/strato-components-preview'
import React, { useState } from 'react'
import { CopyIcon, ExternalLinkIcon, OpenWithIcon, SuccessIcon } from '@dynatrace/strato-icons';
import styled from 'styled-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectService } from '../../../../services/ProjectService';
import { useAppInfo } from '../../../../contexts/AppContext';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0px;
`;

const RequestsUsers = ({ requestStatusData, projectRequests, projectRequestsApiData, handleSelectChange, handleUploadClick, selectedData, openCommentModal, refetchProjectRequests, setProjectRequestsApiData, navigationPath, setNavigationPath, setLoading, setSelectedData, filteredAssignedData, handleNavigationPath, handleRequestEditChange, setSelectedUsersData }: any) => {
    const [copiedRequestId, setCopiedRequestId] = useState<number | null>(null);
    const { ProjectId, RequestId, PeopleId } = useParams();
    const { tenantId } = useAppInfo()
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedRowsInfo, setSelectedRowsInfo] = useState<any>([]);

    const filterData = (data: any[], query: string) => {
        if (!query) return data;
        return data.filter((item) => {
            return Object.values(item).some((value) =>
                typeof value === "string" && value.toLowerCase().includes(query.toLowerCase())
            );
        });
    };

    const filteredAssignedUsers = filterData(projectRequestsApiData.filter((item: any) => item.projectRequestId === Number(RequestId))[0]?.assignedList || [], searchQuery);

    console.log(selectedData, projectRequestsApiData)

    const handleSelectedRows = (
        selectedRows: Record<string, boolean>,
        selectedRowsData?: any[],
        trigger?: 'user' | 'internal',
    ) => {
        setSelectedRowsInfo(selectedRowsData);
    };

    const handleCopy = async (projectRequestUrl: string, rowId: number) => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
        await navigator.clipboard.writeText(fullUrl);
        setCopiedRequestId(rowId);
        setTimeout(() => setCopiedRequestId(null), 2000);
    };

    const handleRedirect = async (projectRequestUrl: string, rowId: number) => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
        window.open(fullUrl, '_blank');
    };

    // const handleNavigationPath = (requestRow: any, level: any) => {
    //     console.log(projectRequestsApiData)
    //     let rawData = projectRequestsApiData.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents")
    //     console.log(rawData)
    //     let filteredData = rawData.filter((item: any) => item.projectRequestId === RequestId)
    //     console.log("RequestId", Number(RequestId), filteredData)
    //     let path = navigationPath.routes || [];
    //     if (level === 'users') {
    //       console.log("rawData", filteredData)
    //       path.push(filteredData?.projectRequestName);
    //       setNavigationPath({ level: level, routes: path, requestId: RequestId });
    //       setSelectedData(filteredData);
    //     } else if (level === 'user-data') {
    //       let peopleData = filteredData[0]?.assignedList?.filter((peopleItem: any) => peopleItem.peopleId === PeopleId)
    //       console.log("peopleData",peopleData)
    //       path.push(peopleData.assignedto);
    //       setNavigationPath({ level: level, routes: path, requestId: RequestId });
    //       setSelectedUsersData(peopleData);
    //     } else {
    //       console.error("Unhandled navigation level:", level);
    //     }
    //   };
    const handleRedirectLinks = () => {
        selectedRowsInfo.map((item: any) => handleRedirect(item.projectRequestUrl, 0))
    }

    const handleOpenUserFiles = async (requestRow: any) => {
        await navigate(`people/${requestRow.peopleId}`);
        if (RequestId) {
            handleNavigationPath(requestRow, 'user-data')
        }
    }

    console.log("selectedRowsInfo", selectedRowsInfo, projectRequestsApiData)

    const RowColumnsWithActions = (onProjectUserClick: (requestRow: any) => void) => [
        {
            id: 'assignedto',
            header: 'Assigned to',
            accessor: 'assignedto',
            minWidth: 300,
            columnType: 'text',
            Cell: ({ row }: any) => {
                const rowData = row.original;
                return (
                    <DataTable.Cell>
                        <Tooltip placement="bottom" text={rowData.assignedto}>
                            <TextEllipsis
                                onClick={() => {
                                    onProjectUserClick(rowData);
                                }}
                                truncationMode="end"
                                style={{
                                    color: 'var(--dt-colors-text-primary-default)',
                                    textDecorationLine: 'underline',
                                    cursor: 'pointer',
                                }}
                            >
                                {rowData.assignedto}
                            </TextEllipsis>
                        </Tooltip>
                    </DataTable.Cell>
                );
            },
        },
        // {
        //     id: 'actions',
        //     header: 'Actions',
        //     accessor: 'projectRequestUrl',
        //     width: 100,
        //     Cell: ({ row }: any) => (
        //         <Flex justifyContent="flex-start">
        //             <Tooltip text={copiedRequestId === row.id ? 'Copied' : 'Copy upload page URL'}>
        //                 <Button onClick={() => handleCopy(row.original.projectRequestUrl, row.id)}>
        //                     <Button.Prefix>
        //                         {copiedRequestId === row.id ? <SuccessIcon /> : <CopyIcon />}
        //                     </Button.Prefix>
        //                 </Button>
        //             </Tooltip>
        //             <Tooltip text={'Open upload page URL'}>
        //                 <Button onClick={() => handleRedirect(row.original.projectRequestUrl, row.id)}>
        //                     <Button.Prefix>
        //                         <ExternalLinkIcon />
        //                     </Button.Prefix>
        //                 </Button>
        //             </Tooltip>
        //         </Flex>
        //     ),
        // },
        {
            id: 'modifiedOn',
            header: 'Last Submittal',
            accessor: 'modifiedOn',
            width: 200,
            columnType: 'text',
            Cell: ({ row }: any) => {
                const rowData = row.original;
                const modifiedOn = rowData.modifiedOn;
                return (
                    <DataTable.Cell>
                        {modifiedOn ? new Date(modifiedOn).toLocaleString() : "Never"}
                    </DataTable.Cell>
                );
            },
        },
        {
            id: 'pendingFilesCount',
            header: 'Awaiting Approval',
            accessor: 'pendingFilesCount',
            width: 100,
            columnType: 'number',
            Cell: ({ row }: any) => {
                const rowData = row.original;
                const pendingCount = rowData.documents.filter((doc: any) => doc.statusName === "Pending").length;
                return (
                    <DataTable.Cell>
                        {pendingCount}/{rowData.documents.length}
                    </DataTable.Cell>
                );
            },
        }
    ];

    return (
        <Flex flexDirection='column' style={{ overflow: 'auto' }}>
            <Flex flexDirection='row' gap={0}>
                <FilterBar
                    onFilterChange={() => {
                    }}
                >
                    <FilterBar.Item name={'search'} label={''}>
                        <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
                    </FilterBar.Item>
                    <FilterBar.Item name={'selectedType'} label={''}>
                        <Button></Button>
                    </FilterBar.Item>
                </FilterBar>

                <Button
                    as="text"
                    onClick={() => handleRequestEditChange(selectedData)}
                    disabled={selectedRowsInfo.length === 1 && (!selectedRowsInfo.length)}
                >
                    Edit request
                </Button>
                <VerticalLine />

                {/* Copy Link Button */}
                <Button
                    as="text"
                    onClick={() => selectedRowsInfo.forEach((row: any) => handleCopy(row.projectRequestUrl, row.id))}
                    disabled={selectedRowsInfo.length === 1 && (!selectedRowsInfo.some((row: any) => !!row.projectRequestUrl))}
                >
                    Copy link
                </Button>
                <VerticalLine />

                {/* Open Link Button */}
                <Button
                    as="text"
                    onClick={handleRedirectLinks}
                    disabled={selectedRowsInfo.length === 1 && (!selectedRowsInfo.some((row: any) => !!row.projectRequestUrl))}
                >
                    Open link
                </Button>
            </Flex>
            <DataTable
                columns={RowColumnsWithActions(handleOpenUserFiles) as any}
                data={filteredAssignedUsers}
                variant={{ rowDensity: 'default', contained: true }}
                selectableRows
                onRowSelectionChange={handleSelectedRows}
            >
            </DataTable>
        </Flex>
    )
}

export default RequestsUsers