import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL } from './AxiosAuthService'; // Replace with your API endpoint
import { Tenant } from '../types/Tenant';

export const TenantService = {
  createTenant: async (request: any): Promise<Tenant> => {
    try {
      const response: any = await axios.post(`${getBaseURL()}/tenant`, request, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getTenants: async <T>(): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/tenant`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getTenantById: async <T>(id: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/tenant/${id}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  updateTenantById: async <T>(values: any): Promise<T> => {
    try {
      const response: any = await axios.put(`${getBaseURL()}/tenant`, values, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getBusinessByTenantId: async <T>(id: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/tenant/businessesByTenant/${id}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getProjectByTenantId: async <T>(id: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/project/projectList`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getProjectTenantById: async <T>(id: number): Promise<T> => {
    try {
      // const response: any = await axios.get(`${BASE_URL}/project`, getAuthHeader());
      // return response.data;
      const response: any = await axios.get(`${getBaseURL()}/tenant/projectByTenant/${id}`, getAuthHeader());
      return response.data.projects;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getStatusByTenant: async <T>(tenantId: number, projectRequeststatus: number): Promise<T> => {
    try {
      // const response: any = await axios.get(`${BASE_URL}/project`, getAuthHeader());
      // return response.data;
      const response: any = await axios.get(
        `${getBaseURL()}/tenant/getStatuesByTenant/${tenantId}/${projectRequeststatus}`,
        getAuthHeader(),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  deleteTenant: async <T>(tenantId: number): Promise<T> => {
    try {
        const response: any = await axios.delete(`${getBaseURL()}/tenant/${tenantId}`, getAuthHeader());
        return response.data;
    } catch (error: any) {
        handleApiError(error);
        throw error;
    }
},
};

const handleApiError = (error: AxiosError) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
  } else if (error.request) {
    console.error('API No Response:', error.request);
  } else {
    console.error('API Error:', error.message);
  }
};
