import { Modal, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { ColumnType, DataTable } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { DownloadIcon, DescriptionIcon, EditIcon, UploadIcon } from '@dynatrace/strato-icons';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import '../../../../App.css';
import { useAppInfo } from '../../../../contexts/AppContext';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { format, parseISO } from 'date-fns';
import { UploadService } from '../../../../services/UploadService';
import { TextInput } from '@dynatrace/strato-components-preview';
import { LoadingStateComponent } from '../../../../components/LoadingStateComponent';
import { useParams } from 'react-router-dom';
import { PeopleService } from '../../../../services/PeopleService';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export interface RequestsProps {
  projectRequests: RequestDetails[];
  refetchProjectRequests: () => void;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  onRequestUpdated: any;
}

export const FileView = (props: RequestsProps) => {
  const {
    onRequestUpdated,
    projectRequests,
    refetchProjectRequests,
    setLoading,
  } = props;

  const [fileToRename, setFileToRename] = useState<any>(null);
  const [newFileName, setNewFileName] = useState<string>('');
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState<any>(null);
  const { ProjectId } = useParams();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { tenantId, documentTypes, tenantRequestReminders: reminderListData, tenantRequestStatusList, user } = useAppInfo();
  const [peopleId, setPeopleId] = useState<any>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await PeopleService.getPepopleByEmail(user?.email ?? '', tenantId);
        setPeopleId(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user?.email]);

  const mimeTypes: {
    pdf: string;
    jpg: string;
    jpeg: string;
    png: string;
    doc: string;
    docx: string;
    pptx: string;
    xlsx: string;
  } = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const getMimeType = (fileExtension: string): string | undefined => {
    return mimeTypes[fileExtension as keyof typeof mimeTypes];
  };

  const handleRenameClick = (file: any) => {
    setFileToRename(file);
    setNewFileName(fileNameInModal(file?.downloadFilePath?.split('/')?.pop()));
    setRenameModalOpen(true);
  };

  const handleRenameConfirm = async () => {
    if (!fileToRename || !newFileName.trim()) {
      ShowErrorNotification('File name cannot be empty.');
      return;
    }

    setIsLoading(true);
    try {
      const updatedFile = {
        ...fileToRename,
        downloadFilePathNew: fileToRename?.downloadFilePath?.replace(/[^/]*$/, newFileName?.trim()) + "." + fileToRename?.downloadFilePath?.split(".")?.pop(),
      };

      const data = await UploadService.UpdateFileNameForStaff(updatedFile, tenantId, updatedFile.projectId);
      await onRequestUpdated(updatedFile);
      ShowSuccessNotification(`File renamed to ${newFileName}.`);
    } catch (error) {
      ShowErrorNotification('Failed to rename file. Please try again.');
    } finally {
      setIsLoading(false);
      setRenameModalOpen(false);
    }
  };

  const previewFileClick = async function (rowInfo: any) {
    try {
      setIsLoading(true);
      const base64File: any = await ProjectService.getUploadFileDataForStaff(rowInfo.downloadFilePath, tenantId);
      if (base64File) {
        setPreviewFile({ uri: `data:${getMimeType(rowInfo?.downloadFilePath?.split('.')?.pop())};base64,${base64File}`, fileName: rowInfo?.downloadFilePath?.split('/')?.pop() });
      }
    }
    catch (e) {
      ShowErrorNotification("Error while showing up the preview");
    }
    finally {
      setIsLoading(false);
    }




  }
  const downloadFileClick = function (rowInfo: any) {
    try {
      const downloadFilePath =
        rowInfo?.downloadFilePath?.includes('uploads/')
          ? rowInfo?.downloadFilePath?.split('uploads/')[1]
          : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
            ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
            : null;

      if (!downloadFilePath) {
        console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
        alert("Invalid file path. Unable to download the file.");
        return;
      }

      const downloadUrl = ProjectService.getDownloadFileHeader(downloadFilePath, tenantId);
      window.open(downloadUrl, "_blank");
    } catch (error) {
      console.error("Error in downloadFileClick:", error);
      alert("An error occurred while trying to download the file.");
    }
  };

  const fileData = projectRequests.filter((item) => item.downloadFilePath !== "" && item.downloadFilePath !== null && item.statusName === "Approved").toReversed();

  const fileColumns = [
    {
      id: 'fileName',
      header: 'File Name',
      width: 400,
      accessor: 'fileName',
      columnType: 'string' as ColumnType,
      Cell: ({ row }: any) => (
        <DataTable.Cell>
          {row?.original?.downloadFilePath?.split('/')?.pop() || 'N/A'}
        </DataTable.Cell>
      ),
    },
    {
      id: 'fileType',
      header: 'File Type',
      accessor: 'fileType',
      columnType: 'string' as ColumnType,
      Cell: ({ row }: any) => (
        <DataTable.Cell>{row?.original?.downloadFilePath?.split('.')?.pop() || 'N/A'}</DataTable.Cell>
      ),
    },
    {
      id: 'createdby',
      header: 'Created by',
      accessor: 'createdby',
      width: 200,
      columnType: 'string' as ColumnType,
      Cell: ({ row }: any) => (
        <DataTable.Cell>{row.original.assignedto || 'N/A'}</DataTable.Cell>
      ),
    },
    {
      id: 'modifiedOn',
      header: 'Updated Date',
      // accessor: 'createdOn',
      width: 200,
      Cell: ({ row }: any) => (
        <DataTable.Cell>{row?.original?.updateDate != null && row?.original?.updateDate != ""? format(parseISO(row?.original?.updateDate), 'MMM dd, yyyy hh:mm a') : row?.original?.createdOn != null && row?.original?.createdOn != "" ? format(parseISO(row?.original?.createdOn), 'MMM dd, yyyy hh:mm a') : 'N/A'}</DataTable.Cell>
      ),
    },
    {
      id: 'source',
      header: 'Source',
      accessor: 'projectRequestName',
      width: 200,
      columnType: 'string' as ColumnType,
    },
    {
      id: 'actions',
      header: 'Actions',
      width: 250,
      Cell: ({ row }: any) => (
        <DataTable.Cell>
          <Flex justifyContent="flex-start">
            <Tooltip text="Rename">
              <Button onClick={() => handleRenameClick(row.original)}>
                <Button.Prefix>
                  <EditIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            {/* <Tooltip text="Preview">
            <Button
            onClick={() => previewFileClick(row.original)}
            >
              <Button.Prefix>
                <DescriptionIcon />
              </Button.Prefix>
            </Button>
          </Tooltip> */}
            <Tooltip text="Download">
              <Button
                onClick={() => downloadFileClick(row.original)}
                disabled={
                  row.original.downloadFilePath === '' ||
                  row.original.downloadFilePath === null
                }
              >
                <Button.Prefix>
                  <DownloadIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
          </Flex>
        </DataTable.Cell>
      ),
    },
  ];

  const fileNameInModal = (fileName: string | undefined) => {
    if (!fileName) {
      return 'N/A';
    }
    const parts = fileName?.split('.');
    return parts?.slice(0, -1)?.join('.') || fileName;
  };

  const handleFileChange = async (event: any) => {
    const files = Array.from(event.target.files);

    if (files.length > 0) {

      try {
        await uploadFiles(files);
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleSelectFilesClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const convertFilesToBase64 = async (files: File[]): Promise<{ name: string; size: number; type: string; base64String: string }[]> => {
    return Promise.all(
      files.map((file) => {
        return new Promise<{ name: string; size: number; type: string; base64String: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              name: file.name,
              size: file.size,
              type: file.type,
              base64String: reader.result as string,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    );
  };

  const uploadFiles = async (files : any) => {
    if (files.length === 0) {
      ShowErrorNotification('No files selected', 'Please select files to upload.');
      return;
    }

    setLoading(true);

    try {
      const filesData = await convertFilesToBase64(files);

      const requestDetails = filesData.map((file) => ({
        label: 'Internal staff files',
        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
        documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
        projectRequestId: 0,
        ProjectId,
        tenantId,
        instructions: 'Internal staff files',
        attachedFiles: [
          {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: file.base64String,
            projectRequestDetailsFilesId: 0,
          },
        ],
      }));

      const payload = {
        projectRequestId: 0,
        requestName: 'Internal staff files',
        requestDescription: 'Internal staff files',
        reminderId: reminderListData?.[0].remindersAutoId,
        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
        documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
        requestDetails: requestDetails,
        document: [],
        requestSendTo: [
          {
            sendTo: user?.email,
            sendCC: '',
            status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
            label: 'Internal staff files',
            instructions: 'Internal staff files',
            peopleId: peopleId,
            documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
          },
        ],
        tenantId,
        ProjectId,
        requestUpdateComments: '',
        status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
      };

      const response: any = await ProjectService.postprojectFiles(payload, tenantId, Number(ProjectId));

      if (response.code === '500') {
        ShowErrorNotification('Upload Failed', response.message || 'An error occurred during upload.');
      } else {
        ShowSuccessNotification('Files Uploaded');
        refetchProjectRequests();
      }
    } catch (error) {
      ShowErrorNotification('Upload Error');
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <LoadingStateComponent loading={isLoading} />
      <Flex flexDirection='column' style={{ overflow: 'auto' }}>

        <>
          <Button variant="accent" onClick={handleSelectFilesClick} style={{ width: '100px' }}>
            <Button.Prefix>
              <UploadIcon />
            </Button.Prefix>
            Upload
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </>

        {/* {selectedFiles.length > 0 && (
          <Surface style={{ padding: '16px' }}>
            <Text style={{ fontWeight: 'bold', marginBottom: '8px' }}>Selected Files:</Text>
            <List style={{ marginBottom: '16px' }}>
              {selectedFiles.map((file, index) => (
                <Text key={index} style={{ padding: '4px 0' }}>{file.name}</Text>
              ))}
            </List>
            <Flex justifyContent="flex-end">
              <Button variant="accent" onClick={uploadFiles} style={{ width: '200px' }}>
                Upload Files
              </Button>
            </Flex>
          </Surface>
        )} */}

        {/* <Sheet
        show={!!previewFile}
        onDismiss={() => setPreviewFile(null)}
        // title={previewFile.fileName+ " Preview"}
      >
        {previewFile && (
          <DocViewer
            documents={[previewFile]}
            pluginRenderers={DocViewerRenderers}
            style={{ height: "80vh", overflow: "true" }}
          />
        )}
      </Sheet> */}
        <Modal
          show={renameModalOpen}
          onDismiss={() => setRenameModalOpen(false)}
          title={"Rename '" + fileToRename?.downloadFilePath?.split('/')?.pop() + "' file"}
          size={'small'}
        >
          <Flex flexDirection="column" columnGap={0}>
            <TextInput
              type="text"
              value={newFileName}
              onChange={(e) => setNewFileName(e)}
              placeholder="Enter new file name"
              style={{ margin: '1rem 0', padding: '0.5rem', width: '100%' }}

            />
            <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
              <Button width='80px' variant='default' onClick={() => setRenameModalOpen(false)}>
                Cancel
              </Button>
              <Button width='80px' variant='accent' color='primary' onClick={handleRenameConfirm}>
                Confirm
              </Button>
            </Flex>
          </Flex>
        </Modal>
        <DataTable
          columns={fileColumns}
          data={fileData}
          sortable
          variant={{ rowDensity: 'default' }}
          style={{ marginBottom: '1rem' }}
          resizable
        />
      </Flex>
    </>
  );
};