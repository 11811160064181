import { Surface } from '@dynatrace/strato-components/layouts';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { ChatIcon, ChevronDownIcon, ChevronRightIcon, DeleteIcon, UploadIcon } from '@dynatrace/strato-icons';
import { Strong, Text } from '@dynatrace/strato-components/typography';
import { StatusConfig } from './status-config';
import { UploadRequestDetails } from '../../types/Request';
import { Colors } from '@dynatrace/strato-design-tokens';
import { ChangeEvent, useRef, useState } from 'react';
import { FileNameState } from '../../types/File';
import { blobToData, handleCommentsRemove, handleUploadFileChange, handleUploadFileRemove, notApplicableCheck, notApplicableStatusUpdate, UploadFileKeys } from './upload-utils';
import { ShowErrorNotification, ShowFileSizeExceededValidationBanner, ShowInvalidExtensionValidationBanner, ShowSuccessNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { hideElement } from '../projects/ProjectStyles.css';
import { Chip } from '@dynatrace/strato-components-preview/content';
import styled from 'styled-components';
import { Checkbox, Modal, TextArea, Tooltip } from '@dynatrace/strato-components-preview';
import { UploadService } from '../../services/UploadService';
export interface InCompleteTasksProps {
  incompleteTasks: UploadRequestDetails[];
  toggleExpand: (projectRequestSentId: number) => void;
  expandedSections: { [key: number]: boolean };
  uploadFileKeys: UploadFileKeys;
  reloadUploadDetails: () => void;
}
type CommentStatus = {
  comments: string;
  Status: string;
};
const ResponsiveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  min-width: 150px; 
  @media (max-width: 768px) {
    min-width: 70px;
    padding: 0;
    
    & .strato-button-label {
      display: none;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    min-width: 150px; 
    white-space: nowrap;
  }
`;

export const IncompleteTasks = (props: InCompleteTasksProps) => {
  const { incompleteTasks, toggleExpand, expandedSections, reloadUploadDetails, uploadFileKeys } = props;
  const [loading, setLoading] = useState(false);
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [fileDataMap, setFileDataMap] = useState<{ [key: string]: { files: File[]; details: any; fileExt: string | ''; } }>({});
  const [fileName, setFileName] = useState<FileNameState>({});
  const [uploadedFiles, setUploadedFiles] = useState<Set<string>>(new Set());
  const [fileExtValid, setFileExtValid] = useState<{ [key: number]: boolean }>({});
  const [fileLimitExceed, setFileLimitExceed] = useState<{ [key: number]: boolean }>({});
  const [isModalOpen, setIsModalOpen] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<Record<string, CommentStatus>>({});
  const [backupComments, setBackupComments] = useState<Record<string, string>>({});
  const MAX_FILE_SIZE = 100 * 1024 * 1024;

  // Define the click event handler
  const handleClick = (index: number) => {
    if (fileInputRefs && fileInputRefs.current[index]) {
      fileInputRefs.current[index].value = '';
    }
    fileInputRefs.current[index]?.click();
  };

  // Define the callback ref function
  const setInputRef = (index: number, element: HTMLInputElement | null) => {
    if (element) {
      fileInputRefs.current[index] = element; // Override or set the new input ref
    }
  };

  const isSubmitDisabled =
    Object.values(fileExtValid).length > 0 || Object.values(fileLimitExceed).length > 0
      ?Object.values(fileLimitExceed).some((isValid) => !isValid) || 
      !Object.values(fileExtValid).every((isValid) => isValid) &&
      !Object.values(fileDataMap).some(({ details }) =>
        details.statusName === "Not Applicable"
      )
      : true;
  // when file is removed
  const handleFileRemove = (documentTypeAutoId: number, projectRequestSentId: number) => {
    handleUploadFileRemove(documentTypeAutoId, projectRequestSentId, setFileDataMap, setFileName, setFileExtValid, setFileLimitExceed, MAX_FILE_SIZE);
  };

  // when file is uploaded
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, details: UploadRequestDetails) => {
    handleUploadFileChange(event, details, setFileDataMap, setFileName, setFileExtValid, setFileLimitExceed, MAX_FILE_SIZE);
  };
  const openBlobOnClick = (base64Data: string, fileName: string) => {
    // Convert base64 to a Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Create a download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    link.click();
  };

  const handleSave = async () => {
    if (Object.keys(fileDataMap).length === 0) {
      ShowErrorNotification('Please select a file to upload');
      return;
    }

    try {
      setLoading(true);
      const { token, fileFormat, projectId, tenantId, peopleId, request } = uploadFileKeys;
      const apiCalls: Promise<{ success: boolean; message: string; fileName: string, projectRequestSentId: string }>[] = [];
      const apiNotApplicableCalls: Promise<{ success: boolean; message: string; projectRequestSentId: string, taskName: string }>[] = [];
      const successfulUploads: string[] = [];
      const successfulTasks: string[] = [];
      const failedUploads: string[] = [];

      // Prepare file upload promises
      Object.keys(fileDataMap).forEach((projectRequestSentId) => {
        const { files, details } = fileDataMap[projectRequestSentId];
        if (details.statusName === 'Not Applicable' && !uploadedFiles.has(details.projectRequestSentId)) {
          apiNotApplicableCalls.push(
            notApplicableStatusUpdate(request, tenantId, projectId, peopleId, token, details)
              .then(() => ({
                success: true,
                message: 'Uploaded successfully',
                projectRequestSentId: details.projectRequestSentId,
                taskName: details.label,
              }))
              .catch((error) => ({
                success: false,
                message: error?.response?.data || 'Upload failed',
                projectRequestSentId: details.projectRequestSentId,
                taskName: details.label,
              }))
          )
        }
        else {
          files.forEach((file, index) => {
            if (
              index === files.length - 1 &&
              !uploadedFiles.has(details.projectRequestSentId) // Exclude successfully uploaded files
            ) {
              if(file.size > MAX_FILE_SIZE){
                const failureMessage = `${file.name}: Exceeds the file size limit of 100 MB`
                failedUploads.push(failureMessage);
              }
              else{
                apiCalls.push(
                  blobToData(file, index, request, tenantId, projectId, peopleId, fileFormat, token, details)
                    .then(() => ({
                      success: true,
                      message: 'Uploaded successfully',
                      fileName: file.name,
                      projectRequestSentId: details.projectRequestSentId,
                    }))
                    .catch((error) => ({
                      success: false,
                      message: error?.response?.data || 'Upload failed',
                      fileName: file.name,
                      projectRequestSentId: details.projectRequestSentId,
                    }))
                );
              }
              
            }
          });
        }
      });

      const results = await Promise.allSettled(apiCalls);
      const resultsNotapplicable = await Promise.allSettled(apiNotApplicableCalls);
      // Process the results
      

      results.forEach((result) => {
        if (result.status === 'fulfilled' && result.value.success) {
          successfulUploads.push(result.value.fileName);
          successfulTasks.push(result.value.projectRequestSentId);
        } else {
          const failureMessage = result.status === 'fulfilled'
            ? `${result.value.fileName}: ${result.value.message}`
            : 'Unknown error during upload';
          failedUploads.push(failureMessage);
        }
      });
      resultsNotapplicable.forEach((result) => {
        if (result.status === 'fulfilled' && result.value.success) {
          successfulUploads.push(result.value.taskName);
          successfulTasks.push(result.value.projectRequestSentId);
        } else {
          const failureMessage = result.status === 'fulfilled'
            ? `${result.value.message}`
            : 'Unknown error during upload';
          failedUploads.push(failureMessage);
        }
      });

      // Batch notifications
      if (successfulUploads.length) {
        setUploadedFiles((prev) => new Set([...Array.from(prev), ...successfulTasks]));
        ShowSuccessNotification(`Successfully uploaded: ${successfulUploads.join(', ')}`);
        const emailSubmissionDocs: any = [];
        Object.keys(fileDataMap).forEach((projectRequestSentId) => {
          const { details } = fileDataMap[projectRequestSentId];
          if (!uploadedFiles.has(details.projectRequestSentId)) {
            const params = {
              token,
              tenantId,
              projectId,
              projectRequestId: request?.projectRequestId,
              projectRequestSentId,
              label: details.label,
              peopleId,
              peoplename: uploadFileKeys.peopleName,
              documentTypeAutoId: details.documentTypeAutoId,
            };
            emailSubmissionDocs.push(params);
          }

        });
        UploadService.postProjectRequestSubmissionEmail(emailSubmissionDocs, Number(tenantId));
        setTimeout(() => {
          reloadUploadDetails();
        }, 500);
        // Ensure the UI reflects the "in progress" status.
      }
      if (failedUploads.length) {

        ShowErrorNotification(`Failed to upload: ${failedUploads.join(', ')}.`);
      }
    } catch (error: any) {
      ShowErrorNotification('Unexpected error uploading files');
    } finally {
      setLoading(false);
      setFileExtValid({});
      setFileLimitExceed({});
      // setIsSubmit(false);
    }
  };

  const handleNotApplicableCheck = (
    event: any,
    task: UploadRequestDetails
  ) => {
    const isChecked = event;
    notApplicableCheck(task, isChecked, setInputValue, setFileDataMap, setFileExtValid);
    if (isChecked) {
      setIsModalOpen(task.projectRequestSentId);
    }

  };

  const handlecommentsClick = (task: UploadRequestDetails) => {
    setBackupComments((prev) => ({
      ...prev,
      [task.projectRequestSentId]: inputValue[task.projectRequestSentId]?.comments || '',
    }));
    setIsModalOpen(task.projectRequestSentId);
  };

  const handleCommentChange = (projectRequestSentId: number, comment: string) => {
    // Update the comment for the specific task's ID
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [projectRequestSentId]: {
        ...prevInputValue[projectRequestSentId], // Preserve other properties like Status
        comments: comment,
      },
    }));
  };

  const handleCommentsRemoveClick = (task: UploadRequestDetails) => {
    handleCommentsRemove(task, setInputValue, setBackupComments);
  }
  // Handle confirm action
  const handleNotApplicableConfirm = () => {
    if (isModalOpen !== null) {
      setFileDataMap((prev) => ({
        ...prev,
        [isModalOpen]: {
          ...prev[isModalOpen],
          details: {
            ...prev[isModalOpen]?.details,
            statusName: 'Not Applicable',
            comments: inputValue[isModalOpen]?.comments || '',
          },
        },
      }));
    }
    setIsModalOpen(null);
  };

  // Handle cancel action
  const handleCancel = () => {
    if (isModalOpen !== null) {
      // Revert comments to the backup state
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [isModalOpen]: {
          ...prevInputValue[isModalOpen],
          comments: backupComments[isModalOpen] || '',
        },
      }));
    }
    setIsModalOpen(null);
  };
  if (loading) {
    return <LoadingStateComponent loading />;
  }
  return (
    <>
      <Flex flexDirection='column' gap={16}>
        <h3>Incomplete Tasks</h3>
        {incompleteTasks.map((task, index) => (
          <Surface
            key={task.projectRequestSentId}
            padding={12}
            style={{
              backgroundColor: StatusConfig[task.statusName as keyof typeof StatusConfig].backgroundColor,
              color: StatusConfig[task.statusName as keyof typeof StatusConfig].color,
            }}
            // key={index}
            elevation='floating'
            height={'auto'}
          >
            <Flex gap={16} flexDirection='column'>
              <Flex
                alignItems='center'
                justifyContent='space-between'
                gap={8}
                onClick={() => toggleExpand(task.projectRequestSentId)}
                onMouseEnter={(e: { currentTarget: { style: { cursor: string; }; }; }) => e.currentTarget.style.cursor = 'pointer'}
              >
                <Flex justifyContent='flex-start' flexDirection='row' gap={8}>
                  <Button
                    size='condensed'
                    style={{ marginTop: '-4px' }}
                  >
                    {expandedSections[task.projectRequestSentId] ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </Button>
                  <Text as='h4'>{task.label}</Text>
                </Flex>
                <Flex justifyContent='flex-end' marginRight={20}>
                  <Strong>
                    <Text as='h4'>{task.statusName === 'New' ? inputValue[task.projectRequestSentId]?.Status === 'Not Applicable' ? 'Not Applicable' : '' : task.statusName}</Text>
                  </Strong>
                </Flex>
              </Flex>

              {/* Conditionally render additional rows */}
              {expandedSections[task.projectRequestSentId] && (
                <Flex flexDirection='column' columnGap={32}>
                  {task.statusName === 'Rejected' ? <>
                    <Flex>
                      <Flex style={{ alignItems: 'center' }}>
                        <Text as="p" style={{ marginLeft: '8px' }}>
                          <Strong>Reason for rejection: </Strong>
                          {task.comments}
                        </Text>
                      </Flex>
                    </Flex>
                  </> : <></>}
                  <Flex flexDirection='row' rowGap={8}>
                    <Surface elevation='floating' className='documentList' title={''} width={'100%'} height={'auto'}>
                      <Text as='p'>{task.instructions}</Text>
                    </Surface>
                    <label htmlFor={`file-input-${index}`} className={hideElement}>
                      Upload File
                    </label>
                    <input
                      type='file'
                      accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .zip"
                      id={`file-input-${index}`}
                      className={hideElement}
                      ref={(el) => setInputRef(index, el)}
                      onChange={(event) => handleFileChange(event, task)}
                    />
                    {(task.statusName === 'New' || task.statusName === 'Rejected') && (
                      <Tooltip text={task.statusName === 'Rejected' ? 'Upload again!' : 'Upload'}>
                        <ResponsiveButton
                          size='condensed'
                          variant='emphasized'
                          style={{
                            height: 'auto',
                          }}
                          onClick={() => { handleClick(index) }}
                          disabled={inputValue.hasOwnProperty(task.projectRequestSentId)}
                        >
                          <Button.Prefix>
                            <UploadIcon />
                          </Button.Prefix>
                          {task.statusName === 'Rejected' ? <Text>Upload again!</Text> : <Text>Upload</Text>}
                        </ResponsiveButton>
                      </Tooltip>
                    )}

                    {/* <Button.Prefix><UploadIcon/></Button.Prefix>Upload</Button> */}
                  </Flex>
                  {task?.projectRequestDetailsFiles && task.projectRequestDetailsFiles.length > 0 && (
                    <Flex flexDirection='row' padding={8}>
                      <Text as='p' style={{ color: Colors.Text.Neutral.Default, marginTop: '5px' }}>
                        Attached file(s):{' '}
                      </Text>
                      <Flex flexDirection='row'>
                        {task?.projectRequestDetailsFiles?.map((file, index) => (
                          <Chip
                            size='default'
                            color='primary'
                            as={'a'}
                            href={'#'}
                            onClick={() => {
                              openBlobOnClick(
                                file.fileData,
                                file.filePath?.split(task.projectRequestDetailsId + '/')[1],
                              );
                            }}
                          >
                            {file?.filePath?.split(task.projectRequestDetailsId + '/')[1]}
                          </Chip>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                  <Flex flexDirection='row'>
                    <Checkbox
                      name={`not-applicable-${task.projectRequestSentId}`}
                      value={inputValue[task.projectRequestSentId]?.Status === 'Not Applicable'}
                      onChange={(e) => handleNotApplicableCheck(e, task)}
                    >
                      Not Applicable
                    </Checkbox>

                    {/* {inputValue[task.projectRequestSentId]?.Status === 'Not Applicable' && (
                      <Button variant="accent" onClick={() => handlecommentsClick(task)}>
                        Comments
                      </Button>
                    )} */}

                  </Flex>

                  <Modal
                    title="Not Applicable"
                    show={isModalOpen !== null}
                    onDismiss={handleCancel}
                    footer={
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button variant="accent" onClick={handleNotApplicableConfirm}>
                          Confirm
                        </Button>
                      </>
                    }
                  >
                    <TextArea
                      width="100%"
                      placeholder="Enter comments"
                      value={inputValue[isModalOpen!]?.comments || ''}
                      onChange={(e) => handleCommentChange(isModalOpen!, e)}
                    />
                  </Modal>



                  {fileExtValid.hasOwnProperty(task.projectRequestSentId) ? (
                    fileExtValid[task.projectRequestSentId] === false && fileDataMap[task.projectRequestSentId].details.statusName !== 'Not Applicable' &&
                    ShowInvalidExtensionValidationBanner(
                      `Invalid file type: ${fileName[task.projectRequestSentId]?.ext || 'Unknown'}. Only file types of .jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt and .zip are allowed`
                    )
                  ) : (
                    null
                  )}
                  {fileLimitExceed.hasOwnProperty(task.projectRequestSentId) ? (
                    fileLimitExceed[task.projectRequestSentId] === false && fileDataMap[task.projectRequestSentId].details.statusName !== 'Not Applicable' &&
                    ShowFileSizeExceededValidationBanner(
                      `File size exceeded: ${fileName[task.projectRequestSentId]?.name || 'Unknown'} has exceeded the file size limit of 100 MB.`
                    )
                  ) : (
                    null
                  )}
                  {inputValue[task.projectRequestSentId] && inputValue[task.projectRequestSentId].Status === 'Not Applicable' && inputValue[task.projectRequestSentId].comments !== undefined && inputValue[task.projectRequestSentId].comments !== '' && (
                    <Surface elevation='raised' padding={12}>
                      <Flex justifyContent='space-between' alignContent='center'>
                        <Flex justifyContent='center' justifyItems='center' flexDirection='row' gap={8}>
                          <Strong>Comments: </Strong>
                          <Text as='p'>
                            {inputValue[task.projectRequestSentId].comments}
                          </Text>
                        </Flex>
                        <Flex justifyContent='flex-end' justifyItems='flex-end' flexDirection='row' gap={8}>
                          <Button
                            size='condensed'
                            color='neutral'
                            onClick={() => handlecommentsClick(task)}
                          >
                            <ChatIcon />
                          </Button>
                          <Button
                            size='condensed'
                            color='critical'
                            onClick={() => {
                              handleCommentsRemoveClick(task);

                              // handleFileRemove(task.documentTypeAutoId, task.projectRequestSentId);
                            }
                            }
                          >
                            <DeleteIcon />
                          </Button>
                        </Flex>
                      </Flex>
                    </Surface>
                  )}
                  {fileName[task.projectRequestSentId] && fileDataMap[task.projectRequestSentId].details.statusName !== 'Not Applicable' && (
                    <Surface elevation='raised' padding={12}>
                      <Flex justifyContent='space-between' alignContent='center'>
                        <Flex justifyContent='center' justifyItems='center' flexDirection='row' gap={8}>
                          <Strong>Uploaded file: </Strong>
                          <Chip variant='emphasized' color='primary'>
                            {fileName[task.projectRequestSentId].name}
                          </Chip>


                        </Flex>
                        {/* {task.statusName === 'Rejected' && (
                          <Text>{task.updatedDate ? format(task.updatedDate, 'MMM dd, yyyy HH:mm') : 'N/A'}</Text>
                   ) } */}
                        {/* <Text>{task.updatedDate ? format(task.updatedDate, 'MMM dd, yyyy HH:mm') : 'N/A'}</Text> */}

                        <Text>{(fileName[task.projectRequestSentId].size / 1024).toFixed(2) + 'kB'}</Text>
                        <Button
                          size='condensed'
                          color='critical'
                          onClick={() => handleFileRemove(task.documentTypeAutoId, task.projectRequestSentId)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Flex>
                    </Surface>
                  )}
                </Flex>
              )}
            </Flex>
          </Surface>
        ))}
      </Flex>
      {/* Submit Section */}
      <Flex flexDirection='row' justifyContent='flex-end' marginTop='auto' marginRight={8}>
        <Button
          variant='accent'
          style={{ height: '50px' }}
          width={'200px'}
          className='submitButton'
          onClick={handleSave}
          disabled={isSubmitDisabled}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};