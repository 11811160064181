import { Flex } from '@dynatrace/strato-components/layouts';
import { Request } from '../../types/Request';
import { Strong, Text } from '@dynatrace/strato-components/typography';
import { Colors } from '@dynatrace/strato-design-tokens';
import { Key, useState } from 'react';
import { Button } from '@dynatrace/strato-components';
import { Label, SelectV2, SelectV2SingleValue } from '@dynatrace/strato-components-preview';
import { ReminderDetails } from '../projects/package/shared-types';
import { UploadService } from '../../services/UploadService';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';

export interface RequestDetailsProps {
  request: Request | undefined;
  reminders: ReminderDetails[] | undefined;
  remindersAutoId: number | undefined;
  peopleId: number | undefined;
  reloadUploadDetails: () => void;
}

export const RequestDetails = (props: RequestDetailsProps) => {
  const { request, reminders, remindersAutoId, peopleId, reloadUploadDetails } = props;
  const [truncateTextFlag, setTruncateTextFlag] = useState(true);
  const truncatedText = request?.projectRequestDescription
    ? request.projectRequestDescription.slice(0, 1000)
    : '';
  console.log(reminders);

  const handleReminderChange = async (value:SelectV2SingleValue<number>) => {
    console.log(request);
    try{
      if(request && peopleId){
        let mapProjectRequest = request;
        mapProjectRequest.peopleId = peopleId;
        mapProjectRequest.remindersAutoId = Number(value);
        const result :any = await UploadService.updateRecipientEmailReminderInterval(request);
        if(result.code == 500){
          ShowErrorNotification("Failed to update the reminder interval", result.message);
          reloadUploadDetails();
        }
        else{
          ShowSuccessNotification("Reminder interval updated successfully");
        }
      }
    }
    catch(e){
      ShowErrorNotification("Failed to update the reminder interval", e);
    }
    
    
  }
  return (
    <Flex flexDirection='column' gap={16}>
      {/* User Info Section */}
      <Flex flexDirection='column' className='userInfo' title={''}>
        {request && (
          <Flex justifyContent='space-between'>
            <Text as='h3' style={{ textAlign: 'left' }}>
              <strong>{request.projectRequestName}</strong>
            </Text>
            <Text as='h3' style={{ textAlign: 'right' }}>
              <strong>{request.projectRequestId}</strong>
            </Text>
          </Flex>
        )}
        <Flex style={{ backgroundColor: Colors.Background.Container.Primary.Default, padding: '5px' }}>
          <Flex flexDirection='column' justifyContent='flex-start' width={'100%'}>
            <Text style={{ lineHeight: '1.5', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word' }} fontStyle={'text'} as='pre'>
              {truncateTextFlag && (

                <Strong>{truncatedText}
                  {truncatedText !== request?.projectRequestDescription && (
                    <Button onClick={() => setTruncateTextFlag(false)}>show more</Button>
                  )}
                </Strong>


              )}
              {!truncateTextFlag && (
                <Flex>
                  <Strong>{request?.projectRequestDescription} {"  "}
                    {truncatedText !== request?.projectRequestDescription && (
                      <Button onClick={() => setTruncateTextFlag(true)}>show less</Button>
                    )}
                  </Strong>

                </Flex>
              )}

            </Text>
          </Flex>
        </Flex>
        <Label>Manage email Reminder intervals</Label>
        <SelectV2
          value={remindersAutoId}
          onChange={(value) => {
            console.log(value);
            handleReminderChange(value);
          }}
        // disabled={editRequestDetails}
        >
          <SelectV2.Trigger placeholder={'Select a reminder'} />
          <SelectV2.Content width={'auto'}>
            {reminders?.map(
              (option: { remindersId: Key | null | undefined; remindersAutoId: Key | null | undefined; details: string; }) => (
                <SelectV2.Option key={option.remindersAutoId} value={option.remindersAutoId}>
                  {option.details}
                </SelectV2.Option>
              ),
            )}
          </SelectV2.Content>
        </SelectV2>

      </Flex>
    </Flex>
  );
};


