import {
  DataTable,
  FilterBar,
  Modal,
  Sheet,
  TableColumn,
  TextInput,
  TitleBar,
} from '@dynatrace/strato-components-preview';
import { Button, Flex, TextEllipsis, Text } from '@dynatrace/strato-components';
import { useMemo, useState } from 'react';
import { XmarkIcon, PlusIcon, DeleteIcon } from '@dynatrace/strato-icons';
import { ItemInfo } from '../../types/ListItemInfo';
import { TenantSheet } from './TenantSheet';
import { useTenants } from '../../hooks/use-minerva-data';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { TenantService } from '../../services/TenantService';

export interface TenantsProps {
  itemInfo?: ItemInfo;
  close: () => void;
  show: boolean;
}

export const TenantsSheet = (props: TenantsProps) => {
  const { close, show } = props;

  const [showTenants, setShowTenants] = useState(show);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [itemDetailsVisible, setItemDetailsVisible] = useState<ItemInfo | undefined>();
  const [tenantDetailsDelete, setTenantDetailsDelete] = useState<ItemInfo | undefined>();
  const [loading, setLoading] = useState(false);

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'tenantName',
        header: 'Tenant Name',
        accessor: 'tenantName',
        width: 300,
        cell: (cell: any) => (
          <DataTable.Cell>
            <TextEllipsis
              onClick={() => {
                setItemDetailsVisible({
                  id: cell.row.original.tenantId,
                  index: cell.row.index,
                  name: cell.row.original.tenantName,
                  visibility: true,
                });
              }}
              truncationMode='end'
              style={{
                color: 'var(--dt-colors-text-primary-default',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              {cell.row.original.tenantName}
            </TextEllipsis>
          </DataTable.Cell>
        ),
      },
      {
        id: 'tenantContactName',
        header: 'Contact Name',
        accessor: 'tenantContactName',
        width: 200,
      },
      {
        id: 'tenantDescription',
        header: 'Description',
        accessor: 'tenantDescription',
        width: 700,
      },
      {
        id: 'actions',
        header: 'Actions',
        accessor: 'actions',
        width: 100,
        cell: (cell: any) => (
          <DataTable.Cell>
            <Button
              variant="default"
              width="40px"
              onClick={() =>
                setTenantDetailsDelete({
                  id: cell.row.original.tenantId,
                  name: cell.row.original.tenantName,
                  visibility: true,
                })
              }
            >
              <Button.Prefix>
                <DeleteIcon />
              </Button.Prefix>
            </Button>
          </DataTable.Cell>
        ),
      },
    ],
    [],
  );

  const { isLoading, data: tenants, error, refetch } = useTenants();
  if (error) {
    ShowErrorNotification('Error loading tenants', error);
  }

  const filteredData = useMemo(() => {
    return searchQuery && searchQuery.trim().length !== 0
      ? tenants?.filter(
          (tenant) =>
            tenant.tenantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.tenantDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.tenantContactName.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : tenants;
  }, [tenants, searchQuery]);

  // discarded or closed the sheet from escape key
  const dismissTenants = () => {
    close();
  }

  const handleDeleteTenant = async (tenantDetailsDelete: ItemInfo) => {
    try {
      setLoading(true);
      if (tenantDetailsDelete.id !== undefined) {
        await TenantService.deleteTenant(tenantDetailsDelete.id);
        ShowSuccessNotification(`${tenantDetailsDelete.name} deleted successfully`);
        refetch();
      }
    } catch (error) {
      ShowErrorNotification(`Error occurred while deleting: ${tenantDetailsDelete.name}`);
    } finally {
      setLoading(false);
    }
  };

  const handleYesClick = () => {
    if (tenantDetailsDelete) {
      handleDeleteTenant(tenantDetailsDelete);
    }
    setTenantDetailsDelete(undefined);
  };

  const handleNoClick = () => {
    setTenantDetailsDelete(undefined);
  };

  return (
    <Sheet show={showTenants} onDismiss={dismissTenants} style={{ height: '100%' }}>
      <Flex flexDirection='column' margin={8} gap={8}>
        <TitleBar>
          <TitleBar.Title>Tenants</TitleBar.Title>
          <TitleBar.Subtitle>View and manage your tenants </TitleBar.Subtitle>
          <TitleBar.Action>
            <Flex flexDirection='row' gap={8}>
              <Button
                width='75px'
                onClick={() => {
                  setShowTenants(false);
                  dismissTenants();
                }}
                variant='default'
              >
                <Button.Prefix>
                  <XmarkIcon />
                </Button.Prefix>
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>

        <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginBottom={16} marginTop={8}>
          <FilterBar
            onFilterChange={() => {
            }}
          >
            <FilterBar.Item name={'search'} label={''}>
              <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
            </FilterBar.Item>
            <FilterBar.Item name={'selectedType'} label={''}>
              <Button></Button>
            </FilterBar.Item>
          </FilterBar> 

          <Button
            onClick={() => {
              setItemDetailsVisible({ visibility: true });
            }}
            variant='accent'
            color='primary'
            style={{ margin: '0px 0px 0px auto' }}
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            Tenant
          </Button>
        </Flex>
      </Flex>
      <Flex flexDirection='column' margin={8} padding={0} gap={8}>
        <LoadingStateComponent loading={isLoading || loading} />
        <DataTable
          loading={isLoading}
          columns={columns}
          data={filteredData ?? tenants ?? []}
          sortable
          variant={{ rowDensity: 'default' }}
          sortBy={{ id: 'tenantName', desc: false }}
        ></DataTable>

        {itemDetailsVisible && itemDetailsVisible.visibility && (
          <TenantSheet
            closeDetails={(itemInfo?: ItemInfo) => {
              //reload the page as needed
              setItemDetailsVisible(itemInfo);
              if (itemInfo?.refreshParent) {
                //reload the page
                refetch();
              }
            }}
            show
            itemInfo={itemDetailsVisible}
          />
        )}
        <Modal
          title={`Delete tenant '${tenantDetailsDelete?.name}'`}
          show={tenantDetailsDelete?.visibility}
          onDismiss={handleNoClick}
          size="small"
        >
          <Text>Are you sure you want to delete this tenant?</Text>
          <br/><br/>
            <Text style={{color: 'red'}}>Warning!!</Text>
            <br/> 
            <Text>All assets including projects and files belonging to this tenant will be deleted.  </Text>
            <br/>
            <Text> Once deleted, it cannot be recovered.</Text>
            <br/><br/>
          <Flex flexDirection="row" justifyContent="flex-end" rowGap={4}>
            <Button width="80px" variant="default" onClick={handleNoClick}>
              Cancel
            </Button>
            <Button width="80px" variant="accent" color="primary" onClick={handleYesClick}>
              Confirm
            </Button>
          </Flex>
        </Modal>
      </Flex>
    </Sheet>
  );
};
