import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL } from './AxiosAuthService'; 
import { State } from '../types/State';

export const PeopleService = {
    getStates: async(tenantId: number): Promise<State[]> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/states?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, createPepople: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/people?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updatePeople: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/people?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getPepoples: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/people?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPepopleByEmail: async <T>(email: string, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/people/email?email=${email}&tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },getBusinessDetailsById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/peopleBusinessRelation/clientsRelation/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, deleteBusinessDetailsById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/peopleBusinessRelation/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPepoplesById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/people/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getBusinessesDetailsByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/tenant/businessesByTenant/${tenantId}`, getAuthHeader());
            return response.data.business;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPeopleByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/tenant/peoplesByTenant/${tenantId}`, getAuthHeader());
            return response.data.peoples;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }
};




const handleApiError = (error: AxiosError) => {
    if (error.response) {
        console.error('API Error Response:', error.response.data);
    } else if (error.request) {
        console.error('API No Response:', error.request);
    } else {
        console.error('API Error:', error.message);
    }
};
