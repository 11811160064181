import { IndustryDetails } from './Industry';
import { LoanTypeDetails } from './Loan';
import { Persona } from './Personas';
import { Project } from './Project';
import { State } from './State';
import { StatusDetails } from './Status';
import { Reminders, Tenant } from './Tenant';
import { User } from './User';
import { DocumentType } from './DocumentType';
import { MinervaUser, UserInfo } from './UserInfo';
import { RequestTemplate } from './RequestTemplate';
import { BusinessCategoryDetails, BusinessIndustryDetails } from './Business';

export interface AppInfo {
  isUserLoggedIn?: boolean;
  loginToken?: string;
  expiry?: number;

  tenantId?: number;
  userInfo?: UserInfo;

  tenant?: Tenant;
  projects?: Project[];

  tenantProjectStatusList?: StatusDetails[];
  tenantRequestStatusList?: StatusDetails[];
  tenantIndustries?: IndustryDetails[];
  tenantLoanTypes?: LoanTypeDetails[];
  tenantBusinessCategories?: BusinessCategoryDetails[];
  tenantBusinessIndustries?: BusinessIndustryDetails[];
  tenantPersonaList?: Persona[];
  tenantStateList?: State[];
  users?: User[];
  peoplePersonas?: Persona[];
  documentTypes?: DocumentType[];
  tenantRequestTemplates?: RequestTemplate[];
  tenantRequestReminders?: Reminders[];  
}

export function getMinervaUserTemplate(): MinervaUser {
  return {
    theme: 'light',
    userToken: '',
    tenantId: 0,
  };
}
