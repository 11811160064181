import { Flex } from '@dynatrace/strato-components/layouts';
import { Button, Text } from '@dynatrace/strato-components';
import { AppHeader, Label, Menu } from '@dynatrace/strato-components-preview';
import { Zyq } from './graphics';
import {
  CheckmarkIcon,
  DarkmodeIcon,
  LightmodeIcon,
  NotificationIcon,
} from '@dynatrace/strato-icons';
import { useEffect, useState } from 'react';
import { setLocalMinervaBrowserTheme } from '../../utils/Utilities';
import { PackageService } from '../../services/PackageService';

// 1. Adjust this interface if you use react-router or
//    pass tenantName and packageShareId from a parent component
export interface PackageHeaderProps {
  tenantName: string;
  packageShareId: number;
}

// 2. Model matching the backend JSON response
interface NotificationDetailResponse {
  notificationId: number;
  packageShareId: number;
  message: string;
  notificationDate: string; // or Date, but typically string from JSON
  isRead: boolean;
}

export const PackageHeader = (props: PackageHeaderProps) => {
  const { tenantName, packageShareId } = props;

  const [darkMode, setDarkMode] = useState<boolean>(
    document.documentElement.getAttribute('data-theme') === 'dark'
  );

  // 3. State to store notifications from the API
  const [notifications, setNotifications] = useState<NotificationDetailResponse[]>([]);

  const [showNotifications, setShowNotifications] = useState(false);

  // 4. Fetch notifications from the server when component loads
  useEffect(() => {
    setLocalMinervaBrowserTheme(darkMode ? 'dark' : 'light');
  }, [darkMode]);

  useEffect(() => {
    // A. If packageShareId is valid, fetch notifications
    if (packageShareId) {
      fetchNotifications(packageShareId);
    }
  }, [packageShareId]);

  const fetchNotifications = async (id: number) => {
    try {
      const response: any = await PackageService.getNotificationsUsingPackageShareId(id);

      setNotifications(response.filter(((notification: { message: string; }) => notification.message !== '')));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  // 5. Mark notification as read locally
  const markAsRead = (notificationId: number) => {
    setNotifications((prev) =>
      prev.map((n) =>
        n.notificationId === notificationId ? { ...n, isRead: true } : n
      )
    );
  };

  const toggleNotifications = () => {
    const willOpen = !showNotifications;
    if (willOpen) {
      setNotifications((prev) =>
        prev.map((n) => ({ ...n, isRead: true })),
      );
    }

    setShowNotifications(willOpen);
  };

  return (
    <Flex flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
      {/* Left Section: Minerva and Tagline */}
      <Flex flexDirection="column" alignItems="flex-start">
        <Flex flexDirection="row" gap={8} alignItems="center">
          <AppHeader.AppIcon
            src={Zyq}
            aria-label="Minerva"
            style={{ width: '32px', height: '32px' }}
          />
          <Text as="h2">Minerva</Text>
          <Text as="h4">SecureShare™</Text>
        </Flex>
      </Flex>

      {/* Center Section: Request Name */}
      <Text as="h3" style={{ textAlign: 'center' }}>
        {tenantName}
      </Text>

      <Flex flexDirection="row" gap={8} alignItems="flex-end">
        <Menu>
          <Menu.Trigger>
            <Button aria-label="Notifications" onClick={toggleNotifications} style={{ position: 'relative' }}>
              <NotificationIcon />
              {notifications.some((n) => !n.isRead) && (
                <span
                  style={{
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    backgroundColor: 'red',
                    color: 'white',
                    borderRadius: '50%',
                    width: '8px',
                    height: '8px',
                  }}
                ></span>
              )}
            </Button>
          </Menu.Trigger>
          {showNotifications && (
            <Menu.Content>
              <Flex
                flexDirection="column"
                gap={12}
                style={{
                  position: 'absolute',
                  minWidth: '300px',
                  padding: '8px',
                  top: '-4px',
                  right: '-20px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#fff',
                  zIndex: 1000,
                  maxHeight: '350px',
                  overflowY: 'auto',  
                }}
              >
                <Label style={{ fontSize: '16px', fontWeight:'bold' }}>Notifications</Label>
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <Flex
                      key={notification.notificationId}
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={8}
                      style={{
                        // backgroundColor: notification.isRead ? '#f8f9fa' : '#e9ecef',
                        padding: '8px',
                        borderRadius: '6px',
                        transition: 'background 0.3s',
                      }}
                    >
                      <Flex flexDirection="column" gap={4}>
                        <Text
                          as="p"
                          style={{
                            fontSize: '14px',
                            margin: '0',
                          }}
                        >
                          {notification.message}
                        </Text>
                        <Text
                          as="span"
                          style={{
                            fontSize: '12px',
                            color: '#6c757d',
                          }}
                        >
                          {new Date(notification.notificationDate).toLocaleString()}
                        </Text>
                      </Flex>
                      {/* {!notification.isRead && (
                        <Button
                          variant="emphasized"
                          aria-label="Mark as read"
                          onClick={() => markAsRead(notification.notificationId)}
                          style={{
                            padding: '4px',
                          }}
                        >
                          <CheckmarkIcon />
                        </Button>
                      )} */}
                    </Flex>
                  ))
                ) : (
                  <Text
                    as="p"
                    style={{
                      fontSize: '14px',
                      textAlign: 'center',
                      margin: '0',
                    }}
                  >
                    No notifications
                  </Text>
                )}
              </Flex>
            </Menu.Content>
          )}

        </Menu>

        {/* Dark Mode Toggle */}
        <Menu>
          <Menu.Trigger>
            <AppHeader.ActionButton
              title={darkMode ? 'Light mode' : 'Dark mode'}
              onClick={() => setDarkMode(!darkMode)}
            >
              {darkMode ? <LightmodeIcon /> : <DarkmodeIcon />}
            </AppHeader.ActionButton>
          </Menu.Trigger>
        </Menu>
      </Flex>
    </Flex>
  );
};
