import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';

export const PackageService = {
    decryptAndFetch: async <T>(token: string): Promise<T> => {
        try {
            const response = await axios.post(
                `${getBaseURL()}/Package/DecryptAndFetch`,
                token,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPackageDetails: async <T>(
        peopleId: number,
        packageId: number,
        tenantId: number,
        projectId: number
    ): Promise<T> => {
        try {
            const url = `${getBaseURL()}/project/getpackagedetails?peopleId=${peopleId}&packageId=${packageId}&tenantId=${tenantId}&projectId=${projectId}`;

            const response = await axios.get(url, {
                headers: {
                    ...getAuthHeader(),
                    'Accept': 'application/json',
                },
            });

            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    downloadSelectedPackages: async (
        selectedUniqueLinks: string[],
        packageName: string
    ): Promise<any> => {
        try {
            const payload = {
                selectedUniqueLinks,
                packageName,
            };
            const response = await axios.post(`${getBaseURL()}/Package/DownloadSelectedPackages`,
                payload,
                {
                    headers: {
                        ...getAuthHeader(),
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                }
            );

            return { blob: response.data, headers: response.headers };
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getNotificationsUsingPackageShareId: async <T>(projectShareId: number): Promise<T> => {
        try {
            const response = await axios.get(
                `${getBaseURL()}/package/getNotificationsUsingPackageShareId/${projectShareId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }
};
