import { useMemo, useState, useEffect } from 'react';
// import { ShowErrorNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ToastContainer } from '@dynatrace/strato-components-preview/notifications';
import { Page } from '@dynatrace/strato-components-preview/layouts';
import { Button, Flex, Strong, Text } from '@dynatrace/strato-components';
import { DataTable, FilterBar, TextInput } from '@dynatrace/strato-components-preview';
import { format, parseISO } from 'date-fns';
import styled from 'styled-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { PackageHeader } from './PackageHeader';
import { useLocation } from 'react-router-dom';
import CreatePackage from '../projects/package/CreatePackage';
import { ProjectService } from '../../services/ProjectService';
import { PackageService } from '../../services/PackageService';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0 2px;
`;

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const PackageLandingPage = ({ token }: { token: string }) => {
    const query = useQuery();
    const [peopleId, setPeopleId] = useState(0);
    const [packageId, setPackageId] = useState(0);
    const [tenantId, setTenantId] = useState(0);
    const [projectId, setProjectId] = useState(0);


    const [searchQuery, setSearchQuery] = useState<string>('');
    const [rowData, setRowData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createPackageSheet, setCreatePackageSheet] = useState(false);
    const [actionsData, setActionsData] = useState<any[]>([]);
    const [projectData, setProjectData] = useState<any>([]);
    const [truncateTextFlag, setTruncateTextFlag] = useState(true);
    const truncatedText = rowData?.packageDetails?.projectPackages[0]?.packageMessage
        ? rowData?.packageDetails?.projectPackages[0]?.packageMessage.slice(0, 1000)
        : '';

    useEffect(() => {
        if (!token) {
            console.error('Token is missing.');
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            try {
                const data = await PackageService.decryptAndFetch<{
                    peopleId: number;
                    projectRequestId: number;
                    projectId: number;
                    tenantId: number;
                }>(token);
                setPeopleId(data.peopleId);
                setPackageId(data.projectRequestId)
                setProjectId(data.projectId)
                setTenantId(data.tenantId)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const fetchPackageDetails = async () => {
            if (!packageId) {
                console.error('Package ID is missing in query parameters.');
                setLoading(false);
                return;
            }

            try {
                const data = await PackageService.getPackageDetails<any>(
                    peopleId,
                    packageId,
                    tenantId,
                    projectId
                );
                setRowData(data);
            } catch (error) {
                console.error('Error fetching package details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPackageDetails();
    }, [packageId]);


    useEffect(() => {
        const fetchProjectDetails = async () => {
            if (projectId && tenantId) {
                try {
                    const response = await ProjectService.getProjectWithDetailsID(projectId, tenantId);
                    setProjectData(response);
                } catch (error) {
                    console.error('Error fetching project details:', error);
                }
            }
        };

        fetchProjectDetails();
    }, [projectId, tenantId]);




    const myRowSelectionChangedListener = (
        selectedRows: Record<string, boolean>,
        selectedRowsData: any,
        trigger: 'user' | 'internal'
    ) => {
        setSelectedRows(selectedRowsData)
    };

    const fileColumns = useMemo(() => [
        {
            id: 'fileName',
            header: 'File name',
            width: 400,
            accessor: 'fileName',
            columnType: 'string' as const,
            Cell: ({ row }: any) => {
                const isNew = !actionsData.some(
                    (action: any) =>
                        action.details.some((detail: any) => detail.uniqueLink === row?.original?.uniqueLink)
                );

                return (
                    <DataTable.Cell>
                        <Flex alignItems="center" gap={2}>
                            <Text style={{ fontWeight: isNew ? 'inherit' : 'inherit' }}>{row?.original?.uniqueLink?.split('/')?.pop() || 'N/A'}</Text>
                            {/* {isNew && <Chip variant='emphasized' style={{ padding: '0px' }} color='success'>New</Chip>} */}
                        </Flex>
                    </DataTable.Cell>
                );
            },
        },
        {
            id: 'fileType',
            header: 'File type',
            accessor: 'fileType',
            columnType: 'string' as const,
            Cell: ({ row }: any) => (
                <DataTable.Cell>
                    {row?.original?.uniqueLink?.split('.')?.pop() || 'N/A'}
                </DataTable.Cell>
            ),
        },
        {
            id: 'createdBy',
            header: 'Created by',
            accessor: 'createdByName',
            width: 200,
            columnType: 'string' as const,
            Cell: ({ row }: any) => (
                <DataTable.Cell>{row.original.createdByName || 'N/A'}</DataTable.Cell>
            ),
        },
        {
            id: 'modifiedOn',
            header: 'Updated date',
            accessor: 'sentOn',
            width: 200,
            Cell: ({ row }: any) => (
                <DataTable.Cell>
                    {row?.original?.sentOn
                        ? format(parseISO(row?.original?.sentOn), 'MMM dd, yyyy hh:mm a')
                        : 'N/A'}
                </DataTable.Cell>
            ),
        },
    ], [actionsData]);

    const handleDownloadSelected = async () => {
        if (!selectedRows || selectedRows.length === 0) {
            console.warn('No rows selected');
            return;
        }

        const selectedUniqueLinks = selectedRows.map((row: any) => row.uniqueLink);

        setActionsData((prev) => [
            ...prev,
            {
                action: 'download',
                timestamp: new Date().toISOString(),
                details: selectedRows,
            },
        ]);
        try {
            if (selectedUniqueLinks.length === 1) {
                const downloadFilePath = selectedUniqueLinks[0].split('uploads/')[1];
                window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
            } else {
                const { blob, headers } = await PackageService.downloadSelectedPackages(
                    selectedUniqueLinks,
                    rowData.packageDetails?.projectPackages[0].packageName
                );

                const filename = rowData.packageDetails?.projectPackages[0].packageName
                    ? rowData.packageDetails?.projectPackages[0].packageName
                    : 'package.zip';

                const fileUrl = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = filename; 
                document.body.appendChild(a);
                a.click();
                a.remove();

                window.URL.revokeObjectURL(fileUrl);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const filteredProjectDetails = useMemo(() => {
        const allDetails = rowData?.projectRequestDetails ?? [];

        if (!searchQuery.trim()) {
            // If there's no search, return the whole array
            return allDetails;
        }

        // Otherwise, return the filtered array
        return allDetails.filter((item: any) => {
            const link = item.uniqueLink?.toLowerCase() || '';
            return (
                link.includes(searchQuery.toLowerCase()) ||
                link.split('/').pop()?.includes(searchQuery.toLowerCase())
            );
        });
    }, [searchQuery, rowData]);

    if (loading) {
        return <LoadingStateComponent loading={true} />;
    }


    return (
        <Flex>
            <LoadingStateComponent loading={false} />
            <Page>
                {/* Header */}
                <ToastContainer />
                {rowData.length !== 0 ? <>
                    <Page.Header>
                        <PackageHeader tenantName={rowData?.tenantDetails?.tenantName} packageShareId={rowData?.packageDetails.projectPackages[0].packageShareId} />
                    </Page.Header>
                    <Page.Main>
                        <Flex flexDirection="column">
                            <Flex justifyContent='space-between'>
                                <Strong style={{ textAlign: 'right' }}>{`${rowData.packageDetails?.projectPackages[0].packageName} - Welcome ${rowData.packageDetails?.projectPackages[0].firstName + " " + rowData.packageDetails?.projectPackages[0].lastName}`}</Strong>
                                <Flex>
                                    <Flex flexDirection='column'>
                                        <Text>{`${projectData?.project?.assignedToName}`}</Text>
                                        <Text style={{ fontSize: '10px' }}>Account contact</Text>
                                    </Flex>
                                    <Text as='h3' style={{ textAlign: 'right' }}>
                                        <strong>{packageId}</strong>
                                    </Text>
                                </Flex>
                            </Flex>
                            <Text>{`Hi ${rowData.packageDetails?.projectPackages[0].firstName},`}</Text>
                            {truncateTextFlag && (
                                            
                                              <Text style = {{lineHeight: '1.5', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word'}} fontStyle={'text'} as='pre' >{truncatedText} 
                                              {truncatedText !== rowData.packageDetails?.projectPackages[0].packageMessage && (
                                                <Button onClick={() => setTruncateTextFlag(false)}>show more</Button>
                                              )}
                                              </Text>
                                              
                                              
                                            
                                          ) }
                                          {!truncateTextFlag && (
                                            <Flex>
                                              <Text style = {{lineHeight: '1.5', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word'}} fontStyle={'text'} as='pre'>{rowData.packageDetails?.projectPackages[0].packageMessage} {"  "} 
                                                
                                              {truncatedText !== rowData.packageDetails?.projectPackages[0].packageMessage && (
                                                <Button onClick={() => setTruncateTextFlag(true)}>show less</Button>
                                              )}
                                              </Text>
                                              
                                            </Flex>
                                          )}
                            <Text>{` Our secure portal will be used to download documents as we go through the lending process.`}</Text>
                        </Flex>
                        {/* <Flex
                        style={{
                            border: `1px solid ${Colors.Border.Neutral.Default}`,
                            borderRadius: '10px',
                            padding: '8px',
                            marginTop: '16px',
                            marginBottom: '16px',
                        }}
                        alignItems="center"
                        gap={4}
                    >
                        <Button variant="accent" onClick={() => setCreatePackageSheet(true)}>
                            <UploadIcon />Upload
                        </Button>
                        <Text>
                            Do you have files to share relevant to this project? Use ProShare's Secure upload capabilities to send files back to us!
                        </Text>
                    </Flex> */}

                        <Flex padding={6}>
                            <FilterBar onFilterChange={() => { }}>
                                <FilterBar.Item name={'search'} label={''}>
                                    <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
                                </FilterBar.Item>
                                <FilterBar.Item name={'selectedType'} label={''}>
                                    <Button></Button>
                                </FilterBar.Item>
                            </FilterBar>
                            <Button onClick={handleDownloadSelected} disabled={selectedRows && selectedRows.length === 0}>Download</Button>
                            {/* <VerticalLine />
                            <Button disabled>Preview</Button> */}
                        </Flex>
                        <CreatePackage
                            isOpen={createPackageSheet}
                            onClose={function (): void {
                                setCreatePackageSheet(!createPackageSheet);
                            }}
                            peopleList={projectData?.projectPeopleRelation ?? []}
                            projectDetails={projectData}
                            onPackageCreated={() => { }}
                        />
                        <DataTable
                            columns={fileColumns as any}
                            data={filteredProjectDetails}
                            selectableRows
                            onRowSelectionChange={myRowSelectionChangedListener}
                        />
                    </Page.Main>
                </> : <></>}
            </Page>
        </Flex>
    );
};

export default PackageLandingPage;
