import { Modal, Sheet, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { ColumnType, DataTable } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { DownloadIcon, DescriptionIcon, EditIcon, UploadIcon, PlusIcon, FeedbackIcon, ExternalLinkIcon, SuccessIcon, CopyIcon } from '@dynatrace/strato-icons';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import '../../../../App.css';
import { useAppInfo } from '../../../../contexts/AppContext';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { format, parseISO } from 'date-fns';
import { UploadService } from '../../../../services/UploadService';
import { Strong, TextEllipsis, TextInput } from '@dynatrace/strato-components-preview';
import { LoadingStateComponent } from '../../../../components/LoadingStateComponent';
import { useParams } from 'react-router-dom';
import { PeopleService } from '../../../../services/PeopleService';
import CreatePackage from '../../package/CreatePackage';
import { groupPackagesByProjectId, ProjectPackageDetails } from '../project-details-utils';
import { handleFileDownload } from '../../request/utils';
import { PackageService } from '../../../../services/PackageService';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export interface RequestsProps {
    projectPackages: ProjectPackageDetails[];
    refetchProjectRequests: () => void;
    setLoading: Dispatch<React.SetStateAction<boolean>>;
    peopleList: any;
    onPackageUpdated: any;
    projectDetails: any;
}

export const PackageView = (props: RequestsProps) => {
    const {
        onPackageUpdated,
        projectPackages,
        refetchProjectRequests,
        setLoading,
        peopleList,
        projectDetails,
    } = props;

    const [fileToRename, setFileToRename] = useState<any>(null);
    const [newFileName, setNewFileName] = useState<string>('');
    const [renameModalOpen, setRenameModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [previewFile, setPreviewFile] = useState<any>(null);
    const [createPackageSheet, setCreatePackageSheet] = useState(false);
    const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});
    const [commentModalOpen, setCommentModalOpen] = useState(false);
    const [selectedComment, setSelectedComment] = useState<string | null>(null);
    const [editProjectRequest, setEditProjectRequest] = useState<any>({});
    const { ProjectId } = useParams();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { tenantId, documentTypes, tenantRequestStatusList, user } = useAppInfo();
    const [peopleId, setPeopleId] = useState<any>(null);
    const [copiedRequestId, setCopiedRequestId] = useState<number | null>(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const data = await PeopleService.getPepopleByEmail(user?.email ?? '', tenantId);
                setPeopleId(data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [user?.email]);

    const openCommentModal = (comment: string | null) => {
        setSelectedComment(comment);
        setCommentModalOpen(true);
    };

    const closeCommentModal = () => {
        setCommentModalOpen(false);
        setSelectedComment(null);
    };

    const handlePackageEditChange = async (requestRow: any) => {
        try {
            // setLoading(true);
            //   const data: any = await ProjectService.getProjectPackageByPackageId(requestRow.projectRequestId, requestRow.projectId, tenantId );
            //   if (data) {

            //   }
            setEditProjectRequest(requestRow);
            setCreatePackageSheet(true);
        }
        catch (err) {
            ShowErrorNotification('Failed to load Request ' + requestRow.packagename, err);
        }
        finally {
            // setLoading(false);
        }


    }
    const downloadPackageClick = async function (rowInfo: any) {
        try {
            const selectedUniqueLinks = rowInfo.assignedTo[0].requestPackages.map((item: any) => item.uniqueLink)
            if (selectedUniqueLinks.length === 1) {
                const downloadFilePath = selectedUniqueLinks[0].split('uploads/')[1];
                window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
            } else {
                const { blob, headers }  = await PackageService.downloadSelectedPackages(
                    selectedUniqueLinks, rowInfo?.packageName
                );

                const filename = rowInfo?.packageName
                    ? rowInfo?.packageName
                    : 'package.zip';

                const fileUrl = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();

                window.URL.revokeObjectURL(fileUrl);
            }
        } catch (error) {
            console.error("Error in downloadFileClick:", error);
        }
    };

    const downloadFileClick = async function (rowInfo: any) {
        try {
            const downloadFilePath = rowInfo.uniqueLink.split('uploads/')[1];
            window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
        } catch (error) {
            console.error("Error in downloadFileClick:", error);
        }
    };

    const PackagesData = groupPackagesByProjectId(projectPackages.toReversed());

    const packageColumns = [
        {
            id: 'packageId',
            header: 'Package ID',
            width: 100,
            accessor: 'packageId',
            columnType: 'number' as ColumnType,
            // Cell: ({ row }: any) => (
            //     <DataTable.Cell>
            //         {row?.original?.downloadFilePath?.split('/')?.pop() || 'N/A'}
            //     </DataTable.Cell>
            // ),
        },
        {
            id: 'packageName',
            header: 'Package name',
            width: 400,
            accessor: 'packageName',
            columnType: 'string' as ColumnType,
            Cell: ({ row }: any) => (
                <DataTable.Cell>
                    <Tooltip placement="bottom" text={row?.original?.packageName || 'N/A'}>
                        <TextEllipsis
                            onClick={() => {
                                handlePackageEditChange(row?.original);
                            }}
                            truncationMode="end"
                            style={{
                                color: 'var(--dt-colors-text-primary-default)',
                                textDecorationLine: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            {row?.original?.packageName || 'N/A'}
                        </TextEllipsis>
                    </Tooltip>
                </DataTable.Cell>
            ),
        },
        {
            id: 'modifiedOn',
            header: 'Created On',
            // accessor: 'createdOn',
            width: 200,
            Cell: ({ row }: any) => (
                <DataTable.Cell>{row?.original?.createdDate != null && row?.original?.updateDate != "" ? format(parseISO(row?.original?.createdDate), 'MMM dd, yyyy hh:mm a') : 'N/A'}</DataTable.Cell>
            ),
        },
        {
            id: 'recipientsCount',
            header: 'Recipients Count',
            accessor: 'recipientsCount',
            columnType: 'number' as ColumnType,
            width: 200,
            Cell: ({ row }: any) => {
                return (
                    <DataTable.Cell>{row?.original?.assignedTo.length}</DataTable.Cell>
                );

            }
            ,
        },
        {
            id: 'actions',
            header: 'Actions',
            width: 250,
            Cell: ({ row }: any) => (
                <DataTable.Cell>
                    <Flex justifyContent="flex-start">
                        <Tooltip text="Package comments">
                            <Button
                                onClick={() => openCommentModal(String(row.original.packageUpdateComments))}
                                disabled={
                                    row.original.packageUpdateComments === null
                                    // row.original.downloadFilePath === '' ||
                                    // row.original.downloadFilePath === null
                                    // true
                                }
                            >
                                <Button.Prefix>
                                    <FeedbackIcon />
                                </Button.Prefix>
                            </Button>
                        </Tooltip>
                        <Tooltip text="Download package">
                            <Button
                                onClick={() => downloadPackageClick(row.original)}
                                disabled={
                                    // row.original.downloadFilePath === '' ||
                                    // row.original.downloadFilePath === null
                                    false
                                }
                            >
                                <Button.Prefix>
                                    <DownloadIcon />
                                </Button.Prefix>
                            </Button>
                        </Tooltip>
                    </Flex>
                </DataTable.Cell>
            ),
        },
    ];


    const handleCopy = async (packageRequestURL: string, rowId: number) => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/download/${packageRequestURL}`;
        await navigator.clipboard.writeText(fullUrl);
        setCopiedRequestId(rowId);
        setTimeout(() => setCopiedRequestId(null), 2000);
      };
    
      const handleRedirect = async (packageRequestURL: string, rowId: number) => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/download/${packageRequestURL}`;
        window.open(fullUrl, '_blank');
      };

      console.log("PackagesData",PackagesData)
      
    const recipientColumns = [
        {
            id: 'assignedTo',
            header: 'Assigned To',
            width: 400,
            columnType: 'string' as ColumnType,
            Cell: ({ row }: any) => {
                return (
                    <DataTable.Cell>{row?.original?.sentToUserName}</DataTable.Cell>
                );

            }
        },
        {
              id: 'actions',
              header: 'Actions',
              accessor: 'packageRequestURL',
              width: 100,
              Cell: ({ row }: any) => (
                <Flex justifyContent="flex-start">
                  <Tooltip text={copiedRequestId === row.id ? 'Copied' : 'Copy upload page URL'}>
                    <Button onClick={() => handleCopy(row.original.packageRequestURL, row.id)}>
                      {copiedRequestId === row.id ? <SuccessIcon /> : <CopyIcon />}
                    </Button>
                  </Tooltip>
        
                  <Tooltip text={'Open upload page URL'}>
                    <Button onClick={() => handleRedirect(row.original.packageRequestURL, row.id)}>
                      <ExternalLinkIcon />
                    </Button>
                  </Tooltip>
                </Flex>
              ),
            },
        {
            id: 'filesCount',
            header: 'Files Count',
            width: 200,
            columnType: 'string' as ColumnType,
            Cell: ({ row }: any) => {
                return (
                    <DataTable.Cell>{row?.original?.requestPackages.length}</DataTable.Cell>
                );

            }
        }
    ]

    const RowColumnsWithActions = [
        {
            id: 'fileName',
            header: 'File Name',
            width: 400,
            accessor: 'fileName',
            columnType: 'string' as ColumnType,
            Cell: ({ row }: any) => (
                <DataTable.Cell>
                    {row?.original?.uniqueLink?.split('/')?.pop() || 'N/A'}
                </DataTable.Cell>
            ),
        },
        {
            id: 'actions',
            header: 'Actions',
            accessor: 'projectRequestUrl',
            width: 100,
            Cell: ({ row }: any) => (
                <Flex justifyContent="flex-start">
                    <Tooltip text='Download file'>
                        <Button
                            onClick={() => downloadFileClick(row.original)}
                        >
                            <DownloadIcon />
                        </Button>
                    </Tooltip>
                    {/*
              <Tooltip text={'Open upload page URL'}>
                <Button onClick={() => handleRedirect(row.original.projectRequestUrl, row.id)}>
                  <ExternalLinkIcon />
                </Button>
              </Tooltip> */}
                </Flex>
            ),
        },
        // ...rowColumns.slice(1),
    ];

    const handleFileChange = async (event: any) => {
        const files = Array.from(event.target.files);

        if (files.length > 0) {

            try {
                await uploadFiles(files);
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        }
    };

    const handleSelectFilesClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const convertFilesToBase64 = async (files: File[]): Promise<{ name: string; size: number; type: string; base64String: string }[]> => {
        return Promise.all(
            files.map((file) => {
                return new Promise<{ name: string; size: number; type: string; base64String: string }>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve({
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            base64String: reader.result as string,
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            })
        );
    };

    const uploadFiles = async (files: any) => {
        if (files.length === 0) {
            ShowErrorNotification('No files selected', 'Please select files to upload.');
            return;
        }

        setLoading(true);

        try {
            const filesData = await convertFilesToBase64(files);

            const requestDetails = filesData.map((file) => ({
                label: 'Internal staff files',
                documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
                documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
                projectRequestId: 0,
                ProjectId,
                tenantId,
                instructions: 'Internal staff files',
                attachedFiles: [
                    {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        base64String: file.base64String,
                        projectRequestDetailsFilesId: 0,
                    },
                ],
            }));

            const payload = {
                projectRequestId: 0,
                requestName: 'Internal staff files',
                requestDescription: 'Internal staff files',
                reminderId: 128,
                documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
                documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
                requestDetails: requestDetails,
                document: [],
                requestSendTo: [
                    {
                        sendTo: user?.email,
                        sendCC: '',
                        status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
                        label: 'Internal staff files',
                        instructions: 'Internal staff files',
                        peopleId: peopleId,
                        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
                    },
                ],
                tenantId,
                ProjectId,
                requestUpdateComments: '',
                status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
            };

            const response: any = await ProjectService.postprojectFiles(payload, tenantId, Number(ProjectId));

            if (response.code === '500') {
                ShowErrorNotification('Upload Failed', response.message || 'An error occurred during upload.');
            } else {
                ShowSuccessNotification('Files Uploaded');
                refetchProjectRequests();
            }
        } catch (error) {
            ShowErrorNotification('Upload Error');
        } finally {
            setLoading(false);
        }
    };

    const handleCreatePackageClick = () => {
        setCreatePackageSheet(true);
    }

    console.log(projectPackages)

    return (
        <>
            <LoadingStateComponent loading={isLoading} />
            <CreatePackage
                peopleList={peopleList}
                projectDetails={projectDetails}
                editPackageDetails={editProjectRequest}
                setEditProjectRequest={setEditProjectRequest}
                onPackageCreated={onPackageUpdated} isOpen={createPackageSheet} onClose={function (): void {
                    setCreatePackageSheet(false);
                }}
                projectPackages={projectPackages}
            />
            <Flex flexDirection='column' style={{ overflow: 'auto' }}>

                <Modal
                    show={commentModalOpen}
                    title="Comments"
                    size="small"
                    onDismiss={closeCommentModal}
                >
                    <Flex flexDirection="column" gap={8} width={512}>
                        <Strong>{selectedComment}</Strong>
                        <Flex justifyContent="flex-end">
                            <Button variant="accent" onClick={closeCommentModal} type="button">
                                Close
                            </Button>
                        </Flex>
                    </Flex>
                </Modal>
                <Flex justifyContent="space-between" alignItems="flex-end" style={{ marginBottom: '16px' }}>
                    {/* File Upload Section */}
                    <Flex>
                        <Button onClick={handleSelectFilesClick} style={{ 'visibility': 'hidden' }}>
                            <Button.Prefix>
                                <UploadIcon />
                            </Button.Prefix>
                            Upload
                        </Button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            multiple
                            accept="image/*,application/pdf"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </Flex>


                    {/* Create Package Button */}
                    <Flex>
                        <Button
                            variant="accent"
                            color='primary'
                            onClick={handleCreatePackageClick}
                        >
                            <Button.Prefix>
                                <PlusIcon />
                            </Button.Prefix>
                            Create Package
                        </Button>
                    </Flex>
                </Flex>

                <DataTable
                    columns={packageColumns}
                    data={PackagesData.length > 0 ? PackagesData : []}
                    sortable
                    variant={{ rowDensity: 'default' }}
                    style={{ marginBottom: '1rem' }}
                    resizable
                >
                    <DataTable.ExpandableRow>
                        {({ row }) => {
                            // const requestId = row.projectRequestId;
                            // const projectRequestSentId = row.projectRequestSentId;
                            // if (!fileInputRefs.current[requestId]) {
                            //   fileInputRefs.current[requestId] = [];
                            // }
                            return (
                                <Flex flexDirection='column'>
                                    <DataTable
                                        columns={recipientColumns as any}
                                        data={row?.assignedTo ? row?.assignedTo : []}
                                        variant={{ rowDensity: 'default', contained: false }}
                                    >
                                        <DataTable.ExpandableRow>
                                            {(assignedRow) => {
                                                // const assignedIndex = Number(assignedRow.row.index);

                                                return (
                                                    <Flex flexDirection='column' width={'100%'}>
                                                        <DataTable
                                                            columns={RowColumnsWithActions as any}
                                                            data={assignedRow?.row?.requestPackages ? assignedRow?.row?.requestPackages : []}
                                                            variant={{ rowDensity: 'default', contained: false }}
                                                            resizable
                                                        >
                                                        </DataTable>
                                                    </Flex>
                                                );
                                            }}
                                        </DataTable.ExpandableRow>
                                    </DataTable>
                                </Flex>
                            );
                        }}
                    </DataTable.ExpandableRow>
                </DataTable>
            </Flex>
        </>
    );
};