import { Modal, Sheet, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { ColumnType, DataTable } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import '../../../../App.css';
import { useAppInfo } from '../../../../contexts/AppContext';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { format, parseISO } from 'date-fns';
import { UploadService } from '../../../../services/UploadService';
import { FilterBar, TextInput } from '@dynatrace/strato-components-preview';
import { LoadingStateComponent } from '../../../../components/LoadingStateComponent';
import { useParams } from 'react-router-dom';
import { PeopleService } from '../../../../services/PeopleService';
 
export interface RequestsProps {
  projectRequests: RequestDetails[];
  refetchProjectRequests: () => void;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  onRequestUpdated: any;
  handleSelectedRows: any;
  selectedFilesRowsIds: any;
}
 
export const PackageFilesView = (props: RequestsProps) => {
  const {
    onRequestUpdated,
    projectRequests,
    refetchProjectRequests,
    setLoading,
    handleSelectedRows,
    selectedFilesRowsIds
  } = props;
  const [fileToRename, setFileToRename] = useState<any>(null);
  const [newFileName, setNewFileName] = useState<string>('');
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { ProjectId } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { tenantId, documentTypes, tenantRequestStatusList, user } = useAppInfo();
  const [peopleId, setPeopleId] = useState<any>(null);
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await PeopleService.getPepopleByEmail(user?.email ?? '', tenantId);
        setPeopleId(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
 
    fetchUserData();
  }, [user?.email]);

  const handleRenameConfirm = async () => {
    if (!fileToRename || !newFileName.trim()) {
      ShowErrorNotification('File name cannot be empty.');
      return;
    }
 
    setIsLoading(true);
    try {
      const updatedFile = {
        ...fileToRename,
        downloadFilePathNew: fileToRename?.downloadFilePath?.replace(/[^/]*$/, newFileName?.trim()) + "." + fileToRename?.downloadFilePath?.split(".")?.pop(),
      };
 
      const data = await UploadService.UpdateFileNameForStaff(updatedFile, tenantId, updatedFile.projectId);
      await onRequestUpdated(updatedFile);
      ShowSuccessNotification(`File renamed to ${newFileName}.`);
    } catch (error) {
      ShowErrorNotification('Failed to rename file. Please try again.');
    } finally {
      setIsLoading(false);
      setRenameModalOpen(false);
    }
  };

  const FilteredfileData = projectRequests.filter((item) => item.downloadFilePath !== "" && item.downloadFilePath !== null).toReversed();
  const fileData = FilteredfileData.filter((item: any) =>
    !selectedFilesRowsIds.has(item.projectRequestSentId),
  );
 
  const filteredData = useMemo(() => {
    return searchQuery.trim().length !== 0
      ? fileData?.filter(
        (item: any) =>
          item.downloadFilePath.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.downloadFilePath?.split('/')?.pop().toLowerCase().includes(searchQuery.toLowerCase()),
      )
      : fileData;
  }, [searchQuery, fileData]);
 
  const fileColumns = [
    {
      id: 'fileName',
      header: 'File Name',
      width: 400,
      accessor: 'fileName',
      columnType: 'string' as ColumnType,
      Cell: ({ row }: any) => (
        <DataTable.Cell>
          {row?.original?.downloadFilePath?.split('/')?.pop() || 'N/A'}
        </DataTable.Cell>
      ),
    },
    {
      id: 'fileType',
      header: 'File Type',
      accessor: 'fileType',
      columnType: 'string' as ColumnType,
      Cell: ({ row }: any) => (
        <DataTable.Cell>{row?.original?.downloadFilePath?.split('.')?.pop() || 'N/A'}</DataTable.Cell>
      ),
    },
    {
      id: 'createdby',
      header: 'Created by',
      accessor: 'createdby',
      width: 200,
      columnType: 'string' as ColumnType,
      Cell: ({ row }: any) => (
        <DataTable.Cell>{row.original.assignedto || 'N/A'}</DataTable.Cell>
      ),
    },
    {
      id: 'modifiedOn',
      header: 'Updated Date',
      accessor: 'createdOn',
      width: 200,
      Cell: ({ row }: any) => (
        <DataTable.Cell>{row?.original?.updateDate != null && row?.original?.updateDate != "" ? format(parseISO(row?.original?.updateDate), 'MMM dd, yyyy hh:mm a') : row?.original?.createdOn != null && row?.original?.createdOn != "" ? format(parseISO(row?.original?.createdOn), 'MMM dd, yyyy hh:mm a') : 'N/A'}</DataTable.Cell>),
    },
    {
      id: 'source',
      header: 'Source',
      accessor: 'projectRequestName',
      width: 200,
      columnType: 'string' as ColumnType,
    },
  ];

 
  const handleFileChange = async (event: any) => {
    const files = Array.from(event.target.files);
 
    if (files.length > 0) {
 
      try {
        await uploadFiles(files);
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };
 
  const convertFilesToBase64 = async (files: File[]): Promise<{ name: string; size: number; type: string; base64String: string }[]> => {
    return Promise.all(
      files.map((file) => {
        return new Promise<{ name: string; size: number; type: string; base64String: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              name: file.name,
              size: file.size,
              type: file.type,
              base64String: reader.result as string,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    );
  };
 
  const uploadFiles = async (files: any) => {
    if (files.length === 0) {
      ShowErrorNotification('No files selected', 'Please select files to upload.');
      return;
    }
 
    setLoading(true);
 
    try {
      const filesData = await convertFilesToBase64(files);
 
      const requestDetails = filesData.map((file) => ({
        label: 'Internal staff files',
        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
        documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
        projectRequestId: 0,
        ProjectId,
        tenantId,
        instructions: 'Internal staff files',
        attachedFiles: [
          {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: file.base64String,
            projectRequestDetailsFilesId: 0,
          },
        ],
      }));
 
      const payload = {
        projectRequestId: 0,
        requestName: 'Internal staff files',
        requestDescription: 'Internal staff files',
        reminderId: 128,
        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
        documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
        requestDetails: requestDetails,
        document: [],
        requestSendTo: [
          {
            sendTo: user?.email,
            sendCC: '',
            status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
            label: 'Internal staff files',
            instructions: 'Internal staff files',
            peopleId: peopleId,
            documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
          },
        ],
        tenantId,
        ProjectId,
        requestUpdateComments: '',
        status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
      };
 
      const response: any = await ProjectService.postprojectFiles(payload, tenantId, Number(ProjectId));
 
      if (response.code === '500') {
        ShowErrorNotification('Upload Failed', response.message || 'An error occurred during upload.');
      } else {
        ShowSuccessNotification('Files Uploaded');
        refetchProjectRequests();
      }
    } catch (error) {
      ShowErrorNotification('Upload Error');
    } finally {
      setLoading(false);
    }
  };
 
  const myRowSelectionChangedListener = (
    selectedRows: Record<string, boolean>,
    selectedRowsData: unknown[],
    trigger: 'user' | 'internal'
  ) => {
    handleSelectedRows(
      selectedRowsData.map((item: any) => ({
        ...item,
        fileName: item.downloadFilePath?.split('/')?.pop() || 'N/A',
      }))
    );
  };
 
 
  return (
    <>
      {/* <FileRender /> */}
      <LoadingStateComponent loading={isLoading} />
      <Flex flexDirection='column' style={{ overflow: 'auto' }}>
 
        <>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </>
 
        <FilterBar onFilterChange={() => { }}>
          <FilterBar.Item name={'search'} label={''}>
            <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
          </FilterBar.Item>
          <FilterBar.Item name={'selectedType'} label={''}>
            <Button></Button>
          </FilterBar.Item>
        </FilterBar>
 
        {/* {selectedFiles.length > 0 && (
          <Surface style={{ padding: '16px' }}>
            <Text style={{ fontWeight: 'bold', marginBottom: '8px' }}>Selected Files:</Text>
            <List style={{ marginBottom: '16px' }}>
              {selectedFiles.map((file, index) => (
                <Text key={index} style={{ padding: '4px 0' }}>{file.name}</Text>
              ))}
            </List>
            <Flex justifyContent="flex-end">
              <Button variant="accent" onClick={uploadFiles} style={{ width: '200px' }}>
                Upload Files
              </Button>
            </Flex>
          </Surface>
        )} */}
          <DataTable
            columns={fileColumns}
            data={filteredData}
            sortable
            variant={{ rowDensity: 'default' }}
            resizable
            onRowSelectionChange={myRowSelectionChangedListener}
            selectableRows            
            height={400}
            />
        <Modal
          show={renameModalOpen}
          onDismiss={() => setRenameModalOpen(false)}
          title={"Rename '" + fileToRename?.downloadFilePath?.split('/')?.pop() + "' file"}
          size={'small'}
        >
          <Flex flexDirection="column" columnGap={0}>
            <TextInput
              type="text"
              value={newFileName}
              onChange={(e) => setNewFileName(e)}
              placeholder="Enter new file name"
              style={{ margin: '1rem 0', padding: '0.5rem', width: '100%' }}
 
            />
            <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
              <Button width='80px' variant='default' onClick={() => setRenameModalOpen(false)}>
                Cancel
              </Button>
              <Button width='80px' variant='accent' color='primary' onClick={handleRenameConfirm}>
                Confirm
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </Flex>
    </>
  );
};
 
 