import { Flex } from '@dynatrace/strato-components/layouts';
import { Controller } from 'react-hook-form';
import { PackageFilesView } from '../project-details/Requests/PackageFilesView';
import { Button, FormField, Label, Modal, SelectV2, TextArea, TextInput } from '@dynatrace/strato-components-preview';
import { LinkIcon } from '@dynatrace/strato-icons';
import { useEffect, useState } from 'react';
import { Text } from '@dynatrace/strato-components';
import { ShowValidationBanner } from '../../../utils/Notifications';

export interface RequestMessageProps {
  control: any;
  fields: Record<'id', string>[];
  selectedFiles: File[][];
  handleFileRemove: (file: File, index: number) => void;
  fileInputRefs: React.MutableRefObject<HTMLInputElement[]>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  removeFileSection: (index: number) => void;
  remove: (index: number) => void;
  editPackageDetails: any;
  updateProjectRequest: any;
  editRequestFlag: boolean;
  setEditRequestFlag: any;
  disableReqeustMessage: boolean;
  projectRequests: any;
  refetchProjectRequests: any;
  setLoading: any;
  handleAddPackage: any;
  selectedFilesRows: any;
  projectPackages: any;
  validationErrors: any;
}

export const PackageMessage = (props: RequestMessageProps) => {

  const {
    control,
    fields,
    editPackageDetails,
    projectRequests,
    updateProjectRequest,
    refetchProjectRequests,
    setLoading,
    handleAddPackage,
    editRequestFlag,
    setEditRequestFlag,
    selectedFilesRows,
    projectPackages,
    validationErrors
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingSelectedRows, setPendingSelectedRows] = useState<any[]>([]);
  const editPackageName = useState(editPackageDetails.packageName);

  console.log(control)
  const selectedFilesRowsIds = new Set(selectedFilesRows.map((item: any) => item.projectRequestSentId));

  useEffect(() => {
    if (editPackageDetails?.packageName && editRequestFlag) {
      updateProjectRequest(editPackageDetails);
      setEditRequestFlag(false);
      setPendingSelectedRows(editPackageDetails.assignedTo[0].requestPackages)
    }
  }, [editPackageDetails]);

  const handleSelectedFilesRows = () => {
    handleAddPackage(pendingSelectedRows)
    setIsModalOpen(false)
  }

  return (
    <Flex flexDirection="column" gap={16}>
      <Flex flexDirection="column" gap={8}>
        <Controller
          name="packageName"
          control={control}
          rules={{
            required: 'Package name is required',
            validate: (value) => {
              const existingPackageNames = projectPackages.map(((packagee: { packageName: any; }) => packagee.packageName));
              if (existingPackageNames.includes(value) && value !== editPackageName[0]) {
                return 'Package name already exist';
              }
              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField required>
              <Label>Package name</Label>
              <TextInput
                {...field}
                value={field.value}
                placeholder="Enter your package name"
                controlState={{
                  state: error ? 'error' : 'valid',
                  hint: error?.message || '',
                }}
                style={{ width: '80%' }}
              />
            </FormField>
          )}
        />
        <Controller
          name="packageDescription"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Package description is required',
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField required>
              <Label>Package description</Label>
              <TextInput
                {...field}
                value={field.value}
                placeholder="Enter your package description"
                controlState={{
                  state: error ? 'error' : 'valid',
                  hint: error?.message || '',
                }}
                style={{ width: '80%' }}
              />
            </FormField>
          )}
        />
        <Controller
          name="packageMessage"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Package message is required',
            },
            maxLength: {
              value: 4000,
              message: 'Package Message cannot have more than 4000 characters.',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField required>
              <Label>Package message</Label>
              <TextArea
                {...field}
                value={field.value}
                placeholder="Enter your message"
                controlState={{
                  state: error ? 'error' : 'valid',
                  hint: error?.message || '',
                }}
                width={'80%'}
              />
            </FormField>
          )}
        />
      </Flex>
      {validationErrors['Files'] && ShowValidationBanner(validationErrors['Files'], "Add atleast one file to proceed")}
      <Controller
        name="requestPackages"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Text color='#b80031'>{error?.message}</Text>
            <Flex justifyContent="space-between" alignItems="end" style={{ marginBottom: '16px' }}>
              <Button variant="accent" onClick={() => setIsModalOpen(true)}>Add files</Button>
            </Flex>
            <Modal show={isModalOpen} onDismiss={() => setIsModalOpen(false)} title="Select files">
              <Flex flexDirection="column" padding={16} gap={16}>
                <PackageFilesView
                  onRequestUpdated={() => { }}
                  projectRequests={projectRequests}
                  selectedFilesRowsIds={selectedFilesRowsIds}
                  refetchProjectRequests={refetchProjectRequests}
                  setLoading={setLoading}
                  handleSelectedRows={(data: any) => {
                    setPendingSelectedRows(data);
                  }}
                />
                <Flex flexDirection="row" justifyContent="flex-end">
                  <Button variant="default" onClick={() => setIsModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant="accent" onClick={handleSelectedFilesRows}>
                    Confirm
                  </Button>
                </Flex>
              </Flex>
            </Modal>
          </>
        )}
      />
    </Flex>
  );
};
