import { Link, matchPath, useLocation } from 'react-router-dom';
import { AppHeader, Avatar, Breadcrumbs, Menu } from '@dynatrace/strato-components-preview';
import { Flex } from '@dynatrace/strato-components';
import {
  AccountIcon,
  AppsIcon,
  HomeIcon,
  LogoutIcon,
  SelectIndicatorIcon,
  SettingIcon,
  TemplateIcon,
} from '@dynatrace/strato-icons';
import { Link as RouterLink } from 'react-router-dom';
import { Zyq } from './graphics';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TemplatesSheet } from '../pages/request-templates/TemplatesSheet';
import { TenantSheet } from '../pages/tenants/TenantSheet';
import { useAuth0 } from '@auth0/auth0-react';
import { UserSheet } from '../pages/user/UserSheet';
import { useAppInfo } from '../contexts/AppContext';
import { ThemeType } from '../types/Types';
import { ItemInfo } from '../types/ListItemInfo';
import { TenantsSheet } from '../pages/tenants/TenantsSheet';
import { configRoutes } from '../configs/constants';
import { getMinervaBrowserTheme, setLocalMinervaBrowserTheme, updateMinervaBrowserTheme } from '../utils/Utilities';

export const Header = () => {
  const { logout, user } = useAuth0();
  const [tenantSheetVisible, setTenantSheetVisible] = useState<boolean>(false);
  const [tenantsSheetVisible, setTenantsSheetVisible] = useState<boolean>(false);
  const [requestTemplatesSheetVisible, setRequestTemplatesSheetVisible] = useState<boolean>(false);
  const [userProfileSheetVisible, setUserProfileSheetVisible] = useState<ItemInfo>();

  const { tenantId, appInfo, user: MinervaUser } = useAppInfo();

  const [theme, setTheme] = useState<ThemeType>(getMinervaBrowserTheme());

  const currentPath = useLocation();

  const onSelectTheme = (selectedTheme: ThemeType) => () => {
    setLocalMinervaBrowserTheme(selectedTheme);

    updateMinervaBrowserTheme(selectedTheme);
    setTheme(selectedTheme);
  };

  const selectedIcon = <SelectIndicatorIcon />;
  const unSelectedIcon = <SelectIndicatorIcon style={{ visibility: 'hidden' }} />;

  // take the user to the Request Templates page
  const onSelectRequestTemplates = () => {
    setRequestTemplatesSheetVisible(true);
  };

  // take the user to the tenant page
  const onSelectTenant = () => {
    setTenantSheetVisible(true);
  };

  // take the user to the tenants page
  const onSelectTenants = () => {
    setTenantsSheetVisible(true);
  };

  // take the user to the tenant page
  const onSelectUserSheetProfile = () => {
    setUserProfileSheetVisible({
      name: `${MinervaUser?.lastName}, ${MinervaUser?.firstName}`,
      idStr: MinervaUser?.userId,
      tenantName: appInfo?.tenant?.tenantName,
      visibility: true,
    });
  };

  const Logout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const pageIndex: number = configRoutes.findIndex((page) => matchPath(page.routeName, currentPath.pathname));
  const [selectedPage, setSelectedPage] = useState<number>(pageIndex);
  const newIndex = useRef<number>(0);

  // Update selectedPage when currentPath changes
  useEffect(() => {
    newIndex.current = configRoutes.findIndex((page) => matchPath(page.routeName, currentPath.pathname));
    newIndex.current = newIndex.current === -1 ? 0 : newIndex.current;
    setSelectedPage(newIndex.current);
  }, [currentPath]);

  // create abbreviation based on first and last name
  function createInitialsFromName(
    fname: string | undefined,
    lname: string | undefined,
    email: string | undefined,
  ): string {
    let abbr = '';
    if (fname && fname.length > 0) {
      abbr += fname.charAt(0).toUpperCase();
    }
    if (lname && lname.length > 0) {
      abbr += lname.charAt(0).toUpperCase();
    }
    if (abbr.length === 0 && email && email.length > 0) {
      // first get email till @
      const name = email.slice(0, email.indexOf('@'));
      const names = name.split('.');
      if (names.length === 2) {
        abbr = names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
      } else {
        abbr = name.charAt(0).toUpperCase();
      }
    }
    return abbr;
  }

  const headerContent = useMemo(() => {
    return (
      <AppHeader>
        <AppHeader.NavItems>
          <AppHeader.AppNavLink
            appName={appInfo?.tenant?.tenantName}
            as={Link}
            to='/projects'
            content='center'
            style={{
              alignItems: 'center',
              verticalAlign: 'middle',
              fontFamily: 'Arial, Helvetica, SanSerif',
              fontWeight: 'bold',
              fontSize: 'large',
            }}
          >
            <AppHeader.AppIcon src={Zyq} aria-label='Minerva' style={{ width: '32px', height: '32px' }} />
          </AppHeader.AppNavLink>
        </AppHeader.NavItems>
        <AppHeader.ActionItems>
          <Flex width={'100px'} />
          <Flex width={'auto'} style={{ minWidth:'200px',verticalAlign: 'middle', flexGrow: 1 }}>
            <Breadcrumbs>
              <Breadcrumbs.Item as={RouterLink} to='/'>
                <HomeIcon />
              </Breadcrumbs.Item>
              {configRoutes[selectedPage]?.parent && (
                <Breadcrumbs.Item as={RouterLink} to={configRoutes[selectedPage]?.parent?.routeName ?? ''}>
                  {configRoutes[selectedPage]?.parent?.friendlyName}
                </Breadcrumbs.Item>
              )}
              <Breadcrumbs.Item disabled as={RouterLink} to={configRoutes[selectedPage]?.routeName}>
                {configRoutes[selectedPage]?.friendlyName}
              </Breadcrumbs.Item>
            </Breadcrumbs>
          </Flex>
          <Menu>
            <Menu.Trigger>
              <AppHeader.ActionButton
                prefixIcon={<SettingIcon size={'default'} />}
                showLabel={false}
                onClick={() => {
                  // call the func
                }}
                aria-label='Tenant Settings'
              >
                Get Started
              </AppHeader.ActionButton>
            </Menu.Trigger>
            <Menu.Content>
              {appInfo.userInfo?.isAdmin && (
                <Menu.Group>
                  <Menu.Item onSelect={onSelectTenants}>
                    <Menu.ItemIcon>
                      <AppsIcon />
                    </Menu.ItemIcon>
                    Tenants (Admin only)
                  </Menu.Item>
                </Menu.Group>
              )}
              <Menu.Group>
                {(appInfo.userInfo?.isTenantAdmin || appInfo.userInfo?.isAdmin) && (
                  <Menu.Item onSelect={onSelectTenant}>
                    <Menu.ItemIcon>
                      <AppsIcon />
                    </Menu.ItemIcon>
                    Tenant
                  </Menu.Item>
                )}
                <Menu.Item onSelect={onSelectRequestTemplates}>
                  <Menu.ItemIcon>
                    <TemplateIcon />
                  </Menu.ItemIcon>
                  Template Mgmt
                </Menu.Item>
              </Menu.Group>{' '}
            </Menu.Content>
          </Menu>
          <Menu>
            <Menu.Trigger>
              <AppHeader.ActionButton
                prefixIcon={
                  <Avatar
                    size={'default'}
                    abbreviation={createInitialsFromName(user?.given_name, user?.family_name, user?.email)}
                  />
                }
                showLabel={false}
                onClick={() => {
                  // call the func
                }}
                aria-label='User Settings'
              >
                Get started
              </AppHeader.ActionButton>
            </Menu.Trigger>
            <Menu.Content>
              <Menu.Item
                onSelect={() => {
                  onSelectUserSheetProfile();
                }}
              >
                <Menu.ItemIcon>
                  <AccountIcon />
                </Menu.ItemIcon>
                User profile
              </Menu.Item>
              <Menu.Group aria-label='more actions'>
                <Menu.Sub>
                  <Menu.SubTrigger>Appearance</Menu.SubTrigger>
                  <Menu.SubContent>
                    <Menu.Item onSelect={onSelectTheme('browser')}>
                      <Menu.ItemIcon>{theme === 'browser' ? selectedIcon : unSelectedIcon}</Menu.ItemIcon>
                      Browser
                    </Menu.Item>
                    <Menu.Item onSelect={onSelectTheme('light')}>
                      <Menu.ItemIcon>{theme === 'light' ? selectedIcon : unSelectedIcon}</Menu.ItemIcon>
                      Light
                    </Menu.Item>
                    <Menu.Item onSelect={onSelectTheme('dark')}>
                      <Menu.ItemIcon>{theme === 'dark' ? selectedIcon : unSelectedIcon}</Menu.ItemIcon>
                      Dark
                    </Menu.Item>
                  </Menu.SubContent>
                </Menu.Sub>
              </Menu.Group>
              <Menu.Item
                onSelect={() => {
                  Logout();
                }}
              >
                <Menu.ItemIcon>
                  <LogoutIcon />
                </Menu.ItemIcon>
                Log out
              </Menu.Item>
            </Menu.Content>
          </Menu>
        </AppHeader.ActionItems>
      </AppHeader>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage, appInfo?.userInfo?.isAdmin, appInfo?.userInfo?.isTenantAdmin, appInfo?.userInfo?.isStaff]);

  return (
    <Flex flexDirection='column'>
      {headerContent}
      {tenantSheetVisible && (
        <TenantSheet
          itemInfo={{ id: tenantId ?? 0, name: appInfo?.tenant?.tenantName }}
          closeDetails={(itemInfo?: ItemInfo) => {
            setTenantSheetVisible(false);
          }}
          show
        />
      )}

      {tenantsSheetVisible && (
        <TenantsSheet
          itemInfo={undefined}
          close={() => {
            setTenantsSheetVisible(false);
          }}
          show
        />
      )}

      {requestTemplatesSheetVisible && (
        <TemplatesSheet
          close={() => {
            setRequestTemplatesSheetVisible(false);
          }}
          show
        />
      )}

      {userProfileSheetVisible && userProfileSheetVisible.visibility && (
        <UserSheet
          closeDetails={(itemInfo?: ItemInfo) => {
            setUserProfileSheetVisible(itemInfo);
          }}
          itemInfo={userProfileSheetVisible}
          show
        />
      )}
    </Flex>
  );
};
