import { FormFieldMessages, Label } from '@dynatrace/strato-components-preview/forms';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useMemo, useState } from 'react';
import { NewProjectPeopleRelationModal } from '../project-relations/NewProjectPeopleRelationModal';
import { DataTable } from '@dynatrace/strato-components-preview/tables';
import { CriticalIcon } from '@dynatrace/strato-icons';
import { Colors } from '@dynatrace/strato-design-tokens';
import { uuidv4 } from '@dynatrace/strato-components-preview/core';
import { PARENT_TYPE_PROJECT } from '../../../types/Types';
import { ItemInfo } from '../../../types/ListItemInfo';
import { ProjectPeopleRelation } from '../../../types/Project';
import { ShowValidationBanner } from '../../../utils/Notifications';

export interface RequestRecipientsProps {
    projectDetails: any;
    peopleList: ProjectPeopleRelation[];
    recipients: ProjectPeopleRelation[];
    handleSubmit: any;
    validationErrors: { [key: string]: string };
    updateProjectPeopleRelations: (addPersonsVisible: ItemInfo) => void;
    selectedRows: Record<string, boolean>;
    handleRecipientsChange: (selectedRows: Record<string, boolean>) => void;
    updateProjectRequest?: any;
    selectedReceipents?: any;
}

export const PackageRecipients = (props: RequestRecipientsProps) => {
  const {projectDetails, peopleList, handleSubmit, validationErrors, updateProjectPeopleRelations, recipients, handleRecipientsChange, selectedRows, updateProjectRequest} = props;
  const [showExistingPersonModal, setShowExistingPersonModal] = useState<boolean>(false);
  const [addPersonsVisible, setAddPersonsVisible] = useState<ItemInfo | undefined>();
  
  const addPerson = () => {
    setAddPersonsVisible({
      id: parseInt(projectDetails?.project?.projectId),
      name: projectDetails?.project.projectName,
      visibility: true,
    });
  };

  const ReceipentColumns = useMemo(
    () => [
      {
        id: 'firstName',
        header: 'First name',
        accessor: 'firstName',
        minWidth: 180,
        autoWidth: true,
      },
      {
        id: 'lastName',
        header: 'Last name',
        accessor: 'lastName',
        minWidth: 200,
        autoWidth: true,
      },
      {
        id: 'email',
        header: 'Email id',
        accessor: 'email',
        autoWidth: true,
      },
    ],
    [],
  );

  console.log(validationErrors['recipients']);
  return (
    <form onSubmit={handleSubmit(() => void 0)} noValidate>
      <Flex flexDirection='column'>
        <Flex flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Flex justifyContent='flex-start'>
            {validationErrors['recipients'] && ShowValidationBanner("Error", "Atleast one recipient must be selected")}
          </Flex>

          <Flex justifyContent='flex-end' gap={8}>
            <Button
              variant='accent'
              onClick={() => {
                addPerson();
                setShowExistingPersonModal(true);
              }}
            >
              Add person
            </Button>
            {showExistingPersonModal && (
              <NewProjectPeopleRelationModal
                key={uuidv4()}
                parentType={PARENT_TYPE_PROJECT}
                parentId={addPersonsVisible?.id ?? 0}
                currentProjectPeopleList={peopleList}
                currentBusinessPeopleList={[]}
                onDismiss={(update) => {
                  setShowExistingPersonModal(false);
                  if (update) {
                    updateProjectPeopleRelations(addPersonsVisible ?? {});
                  }
                }}
              />
            )}
          </Flex>
        </Flex>

        <DataTable
          data={recipients}
          selectableRows={true}
          columns={ReceipentColumns}
          variant={{ contained: false }}
          selectedRows={selectedRows}
          onRowSelectionChange={handleRecipientsChange}
        />
      </Flex>
    </form>
  );
};
