// Handle file download
export const handleFileDownload = (packageRow: any) => {
  //console.log(`Downloading: ${file.name}`);
  
};

// Open file browser for specific section
export const handleBrowseClick = (fileInputRefs: React.MutableRefObject<HTMLInputElement[]>, index: number) => {
  try {
    if (fileInputRefs && fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  }
  catch (err) {
    console.log(err);
  }
};