// src/services/apiService.ts
import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL } from './AxiosAuthService';// Replace with your API endpoint

export const ProjectService = {
    createProject: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/project?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateProject: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/project?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getProject: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, deleteProjectById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/project/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectDetailsById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project/details/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getIndustries: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/master/getIndustrys?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getLoanTypes: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/master/getLoanTypes?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getStatues: async <T>(type: number, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/master/getProjectRequestTemplateStatus/${type}?tenantId=${tenantId}&projectId=${projectId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectsByPeopleID: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/projectPeopleRelation/getProjectByPeople/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectprojectWithDetails: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/project/projectWithDetails?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectWithDetailsID: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project/getProjectWithDetails/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postprojectPeopleRelationWithDetails: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/projectPeopleRelation?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectBusinessesRelationWithDetails: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/projectBusinessesRelation?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    deleteProjectBusinessesRelationWithId: async <T>(bussinessPeopleId: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/projectBusinessesRelation/${bussinessPeopleId}?tenantId=${tenantId}&projectId=${projectId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    deleteProjectPeopleRelationWithId: async <T>(peoplePeopleId: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/projectPeopleRelation/${peoplePeopleId}?tenantId=${tenantId}&projectId=${projectId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectBusinessesRelationWithProjectId: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/projectBusinessesRelation/projectBusinesses/${id}?tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, postprojectRequestWithDetails: async <T>(request: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/project/createProjectRequest?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postPackageFilesWithDetails: async <T>(request: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/package/SavePackage?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    
    updateProjectPackageWithDetails: async <T>(request: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/package/UpdatePackage?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postprojectFiles: async <T>(request: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/project/createProjectFiles?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectRequestById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/ProjectRequest/projectId?projectId=${id}&tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectPackagesById: async <T>(id: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/Package/projectId?projectId=${id}&tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectRequestByRequestId: async <T>(id: number, projectId: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/ProjectRequest/getProjectRequestById/${id}?tenantId=${tenantId}&projectId=${projectId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectPackageByPackageId: async <T>(id: number, projectId: number, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/Package/getProjectPackageByPackageId/${id}?tenantId=${tenantId}&projectId=${projectId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },

    updateProjectRequestWithDetails: async <T>(request: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/Project/updateProjectRequest?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    updateProjectRequestStatus: async <T>(request: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/ProjectRequest/updateProjectRequestStatus?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getDownloadFileHeader: (request: any, tenantId: number): string => {
        return `${getBaseURL()}/ProjectRequest/DownloadFileprojectRequestGet/${request}?tenantId=${tenantId}`;
    },
    getBusinessCategoriesByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/master/getBusinessCategoriesByTenantId/${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },

    getUploadFileDataForStaff: async <T>(downloadPath: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/ProjectRequest/getUploadFileDataForStaff?filePath=${encodeURIComponent(downloadPath)}&tenantId=${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }
};




const handleApiError = (error: AxiosError) => {
    if (error.response) {
        console.error('API Error Response:', error.response.data);
    } else if (error.request) {
        console.error('API No Response:', error.request);
    } else {
        console.error('API Error:', error.message);
    }
};