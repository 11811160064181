import { Modal, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { DataTable, TableRowActions } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { DownloadIcon, UploadIcon, FeedbackIcon, XmarkIcon, CheckmarkIcon, CopyIcon, SuccessIcon, ExternalLinkIcon, OpenWithIcon, EventIcon } from '@dynatrace/strato-icons';
import { columns, rowColumns, rowDocumentsColumns } from './table-definitions';
import { Dispatch, useEffect, Key, useRef, useState } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import { GroupedDocuments, handleFileChange } from '../project-details-utils';
import { hideElement } from '../../ProjectStyles.css';
import { StatusDetails } from '../../../../types/Status';
import '../../../../App.css'
import { useAppInfo } from '../../../../contexts/AppContext';
import { Strong } from '@dynatrace/strato-components';
import CreateRequest from '../../request/CreateRequest';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { Breadcrumbs, FilterBar, TextInput } from '@dynatrace/strato-components-preview';
import RequestsUserFiles from './RequestsUserFiles';
import RequestsUsers from './RequestsUsers';
import { useNavigate, useParams } from 'react-router-dom';
import { Label, SelectV2, SelectV2SingleValue } from '@dynatrace/strato-components-preview';
import { UploadService } from '../../../../services/UploadService';
export interface RequestsProps {
  projectRequestsApiData: any;
  requestStatusData: StatusDetails[];
  handleSelectChange: ((updatedStatus: string, statusOptions: any, cell: any) => void);
  projectRequests: RequestDetails[];
  setProjectRequestsApiData: Dispatch<React.SetStateAction<GroupedDocuments[]>>;
  refetchProjectRequests: () => void;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  projectDetails: any;
  onRequestUpdated: any;
  peopleList: any;
}

export const Requests = (props: RequestsProps) => {
  const { ProjectId, RequestId, PeopleId } = useParams();

  console.log(ProjectId, RequestId)
  const {
    projectDetails,
    onRequestUpdated,
    projectRequestsApiData,
    requestStatusData,
    projectRequests,
    handleSelectChange,
    setProjectRequestsApiData,
    refetchProjectRequests,
    peopleList,
    setLoading,
  } = props;
  const navigate = useNavigate();
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [copiedRequestId, setCopiedRequestId] = useState<number | null>(null);
  const [selectedComment, setSelectedComment] = useState<string | null>(null);
  const [requestCommentModalOpen, setRequestCommentModalOpen] = useState(false);
  const [selectedReminder, setSelectedreminder] = useState<any>(null);
  const [requestReminderModalOpen, setRequestReminderModalOpen] = useState(false);
  const [createRequestSheet, setCreateRequestSheet] = useState(false);
  const [editProjectRequest, setEditProjectRequest] = useState<any>({});
  const [navigationPath, setNavigationPath] = useState<any>({ level: 'requests', routes: [], requestId: 0 });
  const [selectedData, setSelectedData] = useState<any>([]);
  const [selectedUsersData, setSelectedUsersData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filterData = (data: any[], query: string) => {
    if (!query) return data;
    return data.filter((item) => {
      return Object.values(item).some((value) =>
        typeof value === "string" && value.toLowerCase().includes(query.toLowerCase())
      );
    });
  };

  // Filtered Data for Rendering
  const filteredProjectRequests = filterData(projectRequestsApiData, searchQuery);
  const { tenantId, tenantRequestReminders } = useAppInfo();

  const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});

  const downloadFileClick = function (rowInfo: any) {
    //In Local, uncomment the below line
    // window.open(ProjectService.getDownloadFileHeader(rowInfo.original.downloadFilePath));
    //In Dev, uncomment the below Line
    console.log("received", rowInfo)
    const downloadFilePath = rowInfo.downloadFilePath.split('uploads/')[1];
    window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
  };

  const handleUploadClick = (requestId: number, projectRequestSentId: number, documentIndex: number) => {
    if (fileInputRefs.current[requestId] && fileInputRefs.current[requestId][documentIndex]) {
      fileInputRefs.current[requestId][documentIndex]!.click();
    }
  };

  const handleReminderChange = async (value:SelectV2SingleValue<number>) => {
    console.log(selectedReminder, value);
        try{
          setLoading(true);
          if(selectedReminder){
            let mapProjectRequest : any = {};
            mapProjectRequest.peopleId = selectedReminder.peopleId;
            mapProjectRequest.remindersAutoId = value;
            mapProjectRequest.projectRequestId = selectedReminder.projectRequestId;
            mapProjectRequest.projectId =selectedReminder.projectId;
            mapProjectRequest.tenantId =selectedReminder.tenantId;
            setRequestReminderModalOpen(false);
            setSelectedreminder(null);
            const result :any = await UploadService.updateRecipientEmailReminderInterval(mapProjectRequest);
            if(result.code == 500){
              ShowErrorNotification("Failed to update the reminder interval", result.message);
              refetchProjectRequests();
              
            }
            else{
              ShowSuccessNotification("Reminder interval updated successfully");
            }
          }
        }
        catch(e){
          ShowErrorNotification("Failed to update the reminder interval", e);
        }
        finally{
          setLoading(false);
        }
  }

  const openCommentModal = (comment: string | null) => {
    console.log(comment)
    setSelectedComment(comment);
    setCommentModalOpen(true);
  };

  const closeCommentModal = () => {
    setCommentModalOpen(false);
    setSelectedComment(null);
  };

  const handleCopy = async (projectRequestUrl: string, rowId: number) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
    await navigator.clipboard.writeText(fullUrl);
    setCopiedRequestId(rowId);
    setTimeout(() => setCopiedRequestId(null), 2000);
  };

  const handleRedirect = async (projectRequestUrl: string, rowId: number) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
    window.open(fullUrl, '_blank');
  };

  const openRequestReminderModal = async (request: any, rowId: number) => {
    console.log(request);
    setSelectedreminder(request)
    setRequestReminderModalOpen(true);
  };

  const closeRequestReminderModal = () => {
    setRequestReminderModalOpen(false);
    setSelectedreminder(null);
  };


  const RowColumnsWithActions = [
    ...rowColumns.slice(0, 1),
    {
      id: 'actions',
      header: 'Actions',
      accessor: 'projectRequestUrl',
      width: 150,
      Cell: ({ row }: any) => (
        <Flex justifyContent="flex-start">
          <Tooltip text={copiedRequestId === row.id ? 'Copied' : 'Copy upload page URL'}>
            <Button onClick={() => handleCopy(row.original.projectRequestUrl, row.id)}>
              {copiedRequestId === row.id ? <SuccessIcon /> : <CopyIcon />}
            </Button>
          </Tooltip>

          <Tooltip text={'Open upload page URL'}>
            <Button onClick={() => handleRedirect(row.original.projectRequestUrl, row.id)}>
              <ExternalLinkIcon />
            </Button>
          </Tooltip>

          <Tooltip text={'Edit reminder interval'}>
            <Button onClick={() => openRequestReminderModal(row.original, row.id)}>
              <EventIcon />
            </Button>
          </Tooltip>

        </Flex>
      ),
    },
    ...rowColumns.slice(1),
  ];

  const openRequestCommentModal = (comment: string | null) => {
    setSelectedComment(comment);
    setRequestCommentModalOpen(true);
  };

  const closeRequestCommentModal = () => {
    setRequestCommentModalOpen(false);
    setSelectedComment(null);
  };

  const handleRequestEditChange = async (requestRow: any) => {
    try {
      setLoading(true);
      const data: any = await ProjectService.getProjectRequestByRequestId(requestRow.projectRequestId, requestRow.projectId, tenantId);
      if (data) {

      }
      setEditProjectRequest(data);
      setCreateRequestSheet(true);
    }
    catch (err) {
      ShowErrorNotification('Failed to load Request ' + requestRow.projectRequestName, err);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (RequestId && PeopleId && projectRequestsApiData.length > 0) {
      handleNavigationPath({}, 'user-data')
    } else if (RequestId && projectRequestsApiData.length > 0) {
      handleNavigationPath({}, 'users')
    } else {

    }

  }, [RequestId, PeopleId, projectRequestsApiData])

  const handleOpenRequest = async (requestRow: any) => {
    await navigate(`request/${requestRow.projectRequestId}`);
    if (RequestId) {
      handleNavigationPath(requestRow, 'users')
    }
  }

  const rowDocumentsColumnsWithActions = (requestId: any) => [
    ...rowDocumentsColumns,
    {
      id: 'actions',
      header: 'Actions',
      width: 250,
      Cell: ({ row }: any) => {
        const documentIndex = Number(row.original.projectRequestSentId);
        //In Local use the below line
        // const requestRow: any = documentRow.original.original;
        //In Dev use the below line
        const requestRow = row.original;
        if (!fileInputRefs.current[requestId][documentIndex]) {
          fileInputRefs.current[requestId][documentIndex] = null;
        }
        return (
          <Flex justifyContent="flex-start">
            <input
              type='file'
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .zip"
              ref={(el) => (fileInputRefs.current[requestId][documentIndex] = el)}
              className={hideElement}
              onChange={(event) => handleFileChange(
                event,
                requestId,
                requestRow,
                documentIndex,
                projectRequests,
                projectRequestsApiData,
                setProjectRequestsApiData,
                refetchProjectRequests,
                setLoading
              )}
              title='Upload file' />
            <Tooltip text="Approve">
              <Button
                onClick={() => handleSelectChange('Approved', requestStatusData, requestRow)}
                disabled={requestRow.statusName === 'Approved' || requestRow.statusName === 'New'}
              >
                <Button.Prefix>
                  <CheckmarkIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text="Reject">
              <Button
                onClick={() => handleSelectChange('Rejected', requestStatusData, requestRow)}
                disabled={requestRow.statusName === 'Approved' || requestRow.statusName === 'New' || requestRow.statusName === 'Rejected'}
              >
                <Button.Prefix>
                  <XmarkIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text={'Upload document'}>
              <Button
                onClick={(requestRow: any) => handleUploadClick(requestId, requestRow as any, documentIndex)}
                disabled={requestRow.statusName === 'Approved'}
              >
                <Button.Prefix>
                  <UploadIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text={'Download document'}>
              <Button
                onClick={() => {
                  // const item = documentRow.original;
                  downloadFileClick(row.original);
                }}
                disabled={requestRow.statusName === 'New' || requestRow.statusName === 'Rejected' || requestRow.statusName === 'Not Applicable' || requestRow.downloadFilePath === '' || requestRow.downloadFilePath  === null}
              >
                <Button.Prefix>
                  <DownloadIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text={'Show comments'}>
              <Button onClick={() => openCommentModal(String(requestRow.comments))} disabled={requestRow.comments === null || requestRow.comments === "" || (requestRow.statusName !== "Not Applicable" && requestRow.statusName !== "Rejected")}>
                <Button.Prefix>
                  <FeedbackIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
          </Flex>
        )
      },
    },
  ]

  console.log("projectRequestsApiData", projectRequestsApiData.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents"))
  console.log("navdadsdidk", navigationPath, selectedData)

  const handleNavigationPath = (requestRow: any, level: any) => {
    console.log(projectRequestsApiData)
    let rawData = projectRequestsApiData.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents")
    console.log(rawData)
    let filteredData = rawData.filter((item: any) => item.projectRequestId === Number(RequestId))
    console.log("RequestId", Number(RequestId), filteredData)
    let path = navigationPath.routes || [];
    if (level === 'users') {
      console.log("rawData", filteredData)
      path.push(filteredData[0]?.projectRequestName);
      setNavigationPath({ level: level, routes: path, requestId: RequestId });
      setSelectedData(filteredData[0]);
    } else if (level === 'user-data') {
      console.log("filteredData", filteredData)
      path.push(filteredData[0]?.projectRequestName);
      let peopleData = filteredData[0]?.assignedList.filter((peopleItem: any) => peopleItem.peopleId === Number(PeopleId))[0]
      console.log("peopleData", peopleData)
      path.push(peopleData?.assignedto);
      setNavigationPath({ level: level, routes: path, requestId: RequestId });
      setSelectedUsersData(peopleData);
    } else {
      console.error("Unhandled navigation level:", level);
    }
  };

  return (
    <>
      
      <CreateRequest
        isOpen={createRequestSheet}
        onClose={function (): void {
          setCreateRequestSheet(!createRequestSheet);
        }}
        peopleList={peopleList}
        projectDetails={projectDetails}
        onRequestCreated={onRequestUpdated}
        editRequestDetails={editProjectRequest} />
      <Breadcrumbs style={{ marginBottom: '10px' }}>
        <Breadcrumbs.Item
          onClick={() => {
            navigate(`/projects/project/${ProjectId}`);
            setNavigationPath({ routes: [] });
          }}
        >
          All requests
        </Breadcrumbs.Item>

        {RequestId && (
          <Breadcrumbs.Item
            onClick={() => {
              navigate(`/projects/project/${ProjectId}/request/${RequestId}`); 
              setNavigationPath({
                routes: [projectRequestsApiData.find((item: any) => item.projectRequestId === Number(RequestId))?.projectRequestName],
              });
            }}
          >
            {navigationPath.routes[0]} 
          </Breadcrumbs.Item>
        )}

        {RequestId && PeopleId && (
          <Breadcrumbs.Item
            onClick={() => {
              navigate(`/projects/project/${ProjectId}/request/${RequestId}/people/${PeopleId}`);
            }}
          >
            {navigationPath.routes[1]}
          </Breadcrumbs.Item>
        )}
      </Breadcrumbs>

      <Flex flexDirection='column' style={{ overflow: 'auto' }}>
      <Modal
        show={requestReminderModalOpen}
        title={"Manage reminder interval for '" + selectedReminder?.assignedto + "'"} 
        size="small"
        onDismiss={closeRequestReminderModal}
      >
        <Flex flexDirection="column" gap={8} width={512}>
          <SelectV2
            value={selectedReminder?.remindersAutoId}
          onChange={(value) => {
            handleReminderChange(value);
          }}
          >
            <SelectV2.Trigger placeholder={'Select a reminder'} />
            <SelectV2.Content width={'auto'}>
              {tenantRequestReminders?.map(
                (option: { remindersId: Key | null | undefined; remindersAutoId: Key | null | undefined; details: string; }) => (
                  <SelectV2.Option key={option.remindersAutoId} value={option.remindersAutoId}>
                    {option.details}
                  </SelectV2.Option>
                ),
              )}
            </SelectV2.Content>
          </SelectV2>
          <Flex justifyContent="flex-end">
            <Button variant="accent" onClick={closeRequestReminderModal} type="button">
              Close
            </Button>
          </Flex>
        </Flex>
      </Modal>

        {navigationPath.level === 'requests' ?
          (filteredProjectRequests !== undefined && (
            <>
              <Flex flexDirection='row'>
                <FilterBar
                  onFilterChange={() => {
                  }}
                >
                  <FilterBar.Item name={'search'} label={''}>
                    <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
                  </FilterBar.Item>
                  <FilterBar.Item name={'selectedType'} label={''}>
                    <Button></Button>
                  </FilterBar.Item>
                </FilterBar>
              </Flex>
              <DataTable
                columns={columns(handleOpenRequest)}
                data={filteredProjectRequests.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents")}
                sortable
                variant={{ rowDensity: 'default' }}
                sortBy={{ id: 'createdOn', desc: true }}
                style={{ overflowY: 'auto' }}
                resizable
              >
                <DataTable.UserActions>
                  <DataTable.RowActions>
                    {(row) => {
                      // const documentIndex = Number(row.index);
                      // const requestId = row.projectRequestId;
                      //In Local use the below line
                      // const requestRow: any = documentRow.original.original;
                      //In Dev use the below line
                      const requestRow = row.original;
                      // if (!fileInputRefs.current[requestId][documentIndex]) {
                      //   fileInputRefs.current[requestId][documentIndex] = null;
                      // }
                      return (
                        <>
                    <Modal
                          show={requestCommentModalOpen}
                          title="requestComments"
                          size="small"
                          onDismiss={closeRequestCommentModal}
                        >
                          <Flex flexDirection="column" gap={8} width={512}>
                            <Strong>{selectedComment}</Strong>
                            <Flex justifyContent="flex-end">
                              <Button variant="accent" onClick={closeRequestCommentModal} type="button">
                                Close
                              </Button>
                            </Flex>
                          </Flex>
                        </Modal><TableRowActions.Group>
                            <Tooltip text={'Show comments'}>
                              <Button onClick={() => openRequestCommentModal(String(requestRow.requestUpdateComments))} disabled={requestRow.requestUpdateComments === null || requestRow.requestUpdateComments === ""}>
                                <Button.Prefix>
                                  <FeedbackIcon />
                                </Button.Prefix>
                              </Button>
                            </Tooltip>
                          </TableRowActions.Group></>
                      );
                    }}
                  </DataTable.RowActions>
                </DataTable.UserActions>
                <DataTable.ExpandableRow>
                  {({ row }) => {
                    const requestId = row.projectRequestId;
                    // const projectRequestSentId = row.projectRequestSentId;
                    if (!fileInputRefs.current[requestId]) {
                      fileInputRefs.current[requestId] = [];
                    }
                    return (
                      <Flex flexDirection='column' style={{ zIndex: 10000}}>
                        <Modal
                          show={commentModalOpen}
                          title="Comments"
                          size="small"
                          onDismiss={closeCommentModal}
                        >
                          <Flex flexDirection="column" gap={8} width={512}>
                            <Strong>{selectedComment}</Strong>
                            <Flex justifyContent="flex-end">
                              <Button variant="accent" onClick={closeCommentModal} type="button">
                                Close
                              </Button>
                            </Flex>
                          </Flex>
                        </Modal>
                        
                    <DataTable
                          columns={RowColumnsWithActions as any}
                          data={row.assignedList}
                          variant={{ rowDensity: 'default', contained: false }}
                        >
                          <DataTable.ExpandableRow>
                            {(assignedRow) => {
                              // const assignedIndex = Number(assignedRow.row.index);
                              return (
                                <Flex flexDirection='column' width={'100%'}>
                                  <DataTable
                                    columns={rowDocumentsColumnsWithActions(requestId) as any}
                                    data={assignedRow.row.documents}
                                    variant={{ rowDensity: 'default', contained: false }}
                                    resizable
                                  >
                                  </DataTable>
                                </Flex>
                              );
                            }}
                          </DataTable.ExpandableRow>
                        </DataTable>
                      </Flex>
                    );
                  }}
                </DataTable.ExpandableRow>
              </DataTable>
            </>
          )) : (RequestId && PeopleId) ?
            <RequestsUserFiles
              requestStatusData={requestStatusData}
              handleSelectChange={handleSelectChange}
              handleUploadClick={handleUploadClick}
              downloadFileClick={downloadFileClick}
              openCommentModal={openCommentModal}
              projectRequests={projectRequests}
              projectRequestsApiData={projectRequestsApiData}
              refetchProjectRequests={refetchProjectRequests}
              setProjectRequestsApiData={setProjectRequestsApiData}
              setNavigationPath={setNavigationPath}
              navigationPath={navigationPath}
              setLoading={setLoading}
              selectedUsersData={selectedUsersData}
              setSelectedUsersData={setSelectedUsersData}
            /> : (RequestId) ?
              (<RequestsUsers requestStatusData={requestStatusData}
                handleSelectChange={handleSelectChange}
                handleUploadClick={handleUploadClick}
                downloadFileClick={downloadFileClick}
                openCommentModal={openCommentModal}
                projectRequests={projectRequests}
                projectRequestsApiData={projectRequestsApiData.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents")}
                refetchProjectRequests={refetchProjectRequests}
                setProjectRequestsApiData={setProjectRequestsApiData}
                setNavigationPath={setNavigationPath}
                handleNavigationPath={handleNavigationPath}
                navigationPath={navigationPath}
                setSelectedData={setSelectedData}
                handleRequestEditChange={handleRequestEditChange}
                selectedData={selectedData}
                setSelectedUsersData={setSelectedUsersData}
                setLoading={setLoading} />) : <></>
        }
      </Flex></>
  );
};