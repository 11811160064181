import { Button, DataTable, FilterBar, Flex, TextInput, Tooltip } from '@dynatrace/strato-components-preview'
import React, { useRef, useState } from 'react'
import { rowDocumentsColumns } from './table-definitions';
import { hideElement } from '../../ProjectStyles.css';
import { CheckmarkIcon, DownloadIcon, FeedbackIcon, UploadIcon, XmarkIcon } from '@dynatrace/strato-icons';
import { handleFileChange } from '../project-details-utils';
import styled from 'styled-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useParams } from 'react-router-dom';
import { ProjectService } from '../../../../services/ProjectService';
import { PackageService } from '../../../../services/PackageService';
import { useAppInfo } from '../../../../contexts/AppContext';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0px;
`;

const RequestsUserFiles = ({ requestStatusData, projectRequests, projectRequestsApiData, handleSelectChange, handleUploadClick, downloadFileClick, openCommentModal, refetchProjectRequests, setProjectRequestsApiData, navigationPath, setNavigationPath, setLoading, setSelectedUsersData, selectedUsersData }: any) => {
    const { ProjectId, RequestId, PeopleId } = useParams();
    const { tenantId } = useAppInfo();
    const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedRowsInfo, setSelectedRowsInfo] = useState<any>([]);
    const filterData = (data: any[], query: string) => {
        if (!query) return data;
        return data.filter((item) => {
            return Object.values(item).some((value) =>
                typeof value === "string" && value.toLowerCase().includes(query.toLowerCase())
            );
        });
    };

    const handleSelectedRowsStatus = (status: string) => {
        console.log("selectedRowsInfo2",selectedRowsInfo)
        handleSelectChange(status, requestStatusData, selectedRowsInfo[0])
    }

    const handleSelectedRows = (
        selectedRows: Record<string, boolean>,
        selectedRowsData?: any[],
        trigger?: 'user' | 'internal',
    ) => {
        setSelectedRowsInfo(selectedRowsData || []);
    };

    const areAllSelectedRowsApproved = selectedRowsInfo.every(
        (row: any) => row.statusName === 'Approved'
    );
    const areAllSelectedRowsRejected = selectedRowsInfo.every(
        (row: any) => row.statusName === 'Rejected'
    );
    const areSelectedRowsDownloadable = selectedRowsInfo.some(
        (row: any) =>
            row.statusName !== 'New' &&
            row.statusName !== 'Rejected' &&
            row.statusName !== 'Not Applicable' &&
            row.downloadFilePath
    );


    console.log("selectedRowsInfo", selectedRowsInfo, selectedUsersData)
    const filteredAssignedData = filterData(selectedUsersData?.documents || [], searchQuery);


    const handleDownloadSelected = async () => {
        if (!selectedRowsInfo || selectedRowsInfo.length === 0) {
            console.warn('No rows selected');
            return;
        }

        const selectedUniqueLinks = selectedRowsInfo.map((row: any) => row.downloadFilePath);

        try {
            if (selectedUniqueLinks.length === 1) {
                selectedRowsInfo.forEach((item: any) => downloadFileClick(item));
            } else {
                const { blob, headers } = await PackageService.downloadSelectedPackages(
                    selectedUniqueLinks,
                    ''
                );

                const filename = 'package.zip';

                const fileUrl = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();

                window.URL.revokeObjectURL(fileUrl);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const rowDocumentsColumnsWithActions = (requestId: any) => [
        ...rowDocumentsColumns,
        // {
        //     id: 'actions',
        //     header: 'Actions',
        //     width: 250,
        //     Cell: ({ row }: any) => {
        //         const documentIndex = Number(row.original.projectRequestSentId);
        //         //In Local use the below line
        //         // const requestRow: any = documentRow.original.original;
        //         //In Dev use the below line
        //         const requestRow = row.original;
        //         if (!fileInputRefs.current[Number(RequestId)][documentIndex]) {
        //             fileInputRefs.current[Number(RequestId)][documentIndex] = null;
        //           }
        //         return (
        //             <Flex justifyContent="flex-start">
        //                 <input
        //                     type="file"
        //                     accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .zip"
        //                     ref={(el) => {
        //                         // Assign the input element to our refs object
        //                         if (el) {
        //                             fileInputRefs.current[requestId][documentIndex] = el;
        //                         }
        //                     }}
        //                     className={hideElement} // hides the input visually
        //                     onChange={(event) =>
        //                         handleFileChange(
        //                             event,
        //                             requestId,
        //                             requestRow,
        //                             documentIndex,
        //                             projectRequests,
        //                             projectRequestsApiData,
        //                             setProjectRequestsApiData,
        //                             refetchProjectRequests,
        //                             setLoading,
        //                         )
        //                     }
        //                     title="Upload file"
        //                 />
        //                 <Tooltip text="Approve">
        //                     <Button
        //                         onClick={() => handleSelectChange('Approved', requestStatusData, requestRow)}
        //                         disabled={requestRow.statusName === 'Approved' || requestRow.statusName === 'New'}
        //                     >
        //                         <Button.Prefix>
        //                             <CheckmarkIcon />
        //                         </Button.Prefix>
        //                     </Button>
        //                 </Tooltip>
        //                 <Tooltip text="Reject">
        //                     <Button
        //                         onClick={() => handleSelectChange('Rejected', requestStatusData, requestRow)}
        //                         disabled={requestRow.statusName === 'Approved' || requestRow.statusName === 'New' || requestRow.statusName === 'Rejected'}
        //                     >
        //                         <Button.Prefix>
        //                             <XmarkIcon />
        //                         </Button.Prefix>
        //                     </Button>
        //                 </Tooltip>
        //                 <Tooltip text={'Upload document'}>
        //                     <Button
        //                         onClick={(requestRow: any) => handleUploadClick(requestId, requestRow as any, documentIndex)}
        //                         disabled={requestRow.statusName === 'Approved'}
        //                     >
        //                         <Button.Prefix>
        //                             <UploadIcon />
        //                         </Button.Prefix>
        //                     </Button>
        //                 </Tooltip>
        //                 <Tooltip text={'Download document'}>
        //                     <Button
        //                         onClick={() => {
        //                             // const item = documentRow.original;
        //                             downloadFileClick(row.original);
        //                         }}
        //                         disabled={requestRow.statusName === 'New' || requestRow.statusName === 'Rejected' || requestRow.statusName === 'Not Applicable' || requestRow.downloadFilePath === '' || requestRow.downloadFilePath === null}
        //                     >
        //                         <Button.Prefix>
        //                             <DownloadIcon />
        //                         </Button.Prefix>
        //                     </Button>
        //                 </Tooltip>
        //                 <Tooltip text={'Show comments'}>
        //                     <Button onClick={() => openCommentModal(String(requestRow.comments))} disabled={requestRow.comments === null || requestRow.comments === "" || (requestRow.statusName !== "Not Applicable" && requestRow.statusName !== "Rejected")}>
        //                         <Button.Prefix>
        //                             <FeedbackIcon />
        //                         </Button.Prefix>
        //                     </Button>
        //                 </Tooltip>
        //             </Flex>
        //         )
        //     },
        // },
    ]

    console.log(selectedUsersData, "dsdflsd")
    return (

        <Flex flexDirection='column' style={{ overflow: 'auto' }}>
            <Flex flexDirection='row' gap={0}>
                <FilterBar
                    onFilterChange={() => {
                    }}
                >
                    <FilterBar.Item name={'search'} label={''}>
                        <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
                    </FilterBar.Item>
                    <FilterBar.Item name={'selectedType'} label={''}>
                        <Button></Button>
                    </FilterBar.Item>
                </FilterBar>
                <Button
                    as="text"
                    onClick={handleDownloadSelected}
                    disabled={(
                        selectedRowsInfo.length === 0 || (selectedRowsInfo.some(
                            (row: any) =>
                                row.statusName === 'New' || row.statusName === 'Rejected' || row.statusName === 'Not Applicable' || row.downloadFilePath === "" || row.downloadFilePath === null
                        )))
                    }
                >
                    Download
                </Button>
                <VerticalLine />

                <Button
                    as="text"
                    style={{ margin: 0 }}
                    onClick={() => {
                        if (selectedRowsInfo.length > 0) {
                            selectedRowsInfo.forEach((row: any) => {
                                const documentIndex = Number(row.projectRequestSentId); // Ensure projectRequestSentId is correct
                                const inputRef = fileInputRefs.current[Number(RequestId)]?.[documentIndex];
                
                                if (inputRef) {
                                    inputRef.click();
                                } else {
                                    console.warn(
                                        `No input ref found for RequestId: ${RequestId}, DocumentIndex: ${documentIndex}`
                                    );
                                }
                            });
                        } else {
                            console.warn("No rows selected for upload.");
                        }
                    }}
                    disabled={selectedRowsInfo.length === 0 || selectedRowsInfo.some(
                        (row: any) => row.statusName === 'Approved'
                    )}
                >
                    Upload
                </Button>
                <VerticalLine />

                <Button
                    as="text"
                    onClick={() => handleSelectedRowsStatus('Approved')}
                    disabled={selectedRowsInfo.every(
                        (row: any) => row.statusName === 'Approved' || row.statusName === 'New'
                    )}
                >
                    Approve
                </Button>
                <VerticalLine />

                <Button
                    as="text"
                    onClick={() => handleSelectedRowsStatus('Rejected')}
                    disabled={selectedRowsInfo.length === 0 || (selectedRowsInfo.some(
                        (row: any) =>
                            row.statusName === 'Rejected' ||
                            row.statusName === 'Approved' ||
                            row.statusName === 'New'
                    ))}
                >
                    Reject
                </Button>
                <VerticalLine />

                <Button
                    as="text"
                    onClick={() =>
                        selectedRowsInfo.length === 1 && openCommentModal(selectedRowsInfo[0].comments)
                    }
                    disabled={selectedRowsInfo.length === 0 || (selectedRowsInfo.some(
                        (row: any) =>
                        (row.comments === null || row.comments === "" || (row.statusName !== "Not Applicable" && row.statusName !== "Rejected"))
                    ))}
                >
                    View comment
                </Button>
            </Flex>
            <DataTable
                columns={rowDocumentsColumnsWithActions(navigationPath.requestId) as any}
                data={filteredAssignedData}
                variant={{ rowDensity: 'default', contained: true }}
                resizable
                selectableRows
                onRowSelectionChange={handleSelectedRows}
            >
            </DataTable>
        </Flex >
    )
}

export default RequestsUserFiles