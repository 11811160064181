import {
  DataTable,
  FilterBar,
  Modal,
  Page,
  TableColumn,
  TableRowActions,
  TextInput,
  TitleBar,
  Tooltip,
} from '@dynatrace/strato-components-preview';
import { Button, TextEllipsis, Flex, Text } from '@dynatrace/strato-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { units } from '@dynatrace-sdk/units';
import { DeleteIcon, EditIcon, PlusIcon } from '@dynatrace/strato-icons';
import { ProjectSheet } from './ProjectSheet';
import { ItemInfo } from '../../types/ListItemInfo';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../types/Project';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { formatDate } from '@dynatrace-sdk/units';
import { configRoutes } from '../../configs/constants';
import { useProjectsByTenantId } from '../../hooks/use-minerva-data';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ProjectService } from '../../services/ProjectService';

export interface ProjectsProps { }

export const ProjectsPage = () => {
  const [itemDetailsVisible, setItemDetailsVisible] = useState<ItemInfo | undefined>();
  const [projectDetailsDelete, setProjectDetailsDelete] = useState<ItemInfo | undefined>();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  let { isAppLoading, tenantId, user } = useAppInfo();

  const prevTenantId = useRef<number>(tenantId);
  const redirectUrl = localStorage.getItem("redirectAfterLogin");
  const navigate = useNavigate();
  
  const redirectPage = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
      localStorage.removeItem("redirectAfterLogin"); // Clean up
    } else {
      navigate("/projects"); // Default to home if no redirect is saved
    }
  }

  
  
  function showProjectDetails(itemInfo: ItemInfo) {
    navigate(`project/${encodeURIComponent(itemInfo.id ? itemInfo.id : '')}`);
  }

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'projectName',
        header: 'Project Name',
        accessor: 'projectName',
        width: 300,
        cell: (cell: any) => (
          <DataTable.Cell>
            <Tooltip placement='bottom' text={cell.row.original.projectName}>
              {
                <TextEllipsis
                  onClick={() => {
                    showProjectDetails({
                      id: cell.row.original.projectId,
                      index: cell.row.index,
                      name: cell.row.original.projectName,
                      visibility: true,
                    });
                  }}
                  truncationMode='end'
                  style={{
                    color: 'var(--dt-colors-text-primary-default',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {cell.row.original.projectName}
                </TextEllipsis>
              }
            </Tooltip>
          </DataTable.Cell>
        ),
      },
      {
        id: 'amount',
        header: 'Amount',
        accessor: 'amount',
        autoWidth: true,
        columnType: 'number',
        formatter: { input: units.currency.usd },
      },
      {
        id: 'createdDateTime',
        header: 'Created on',
        accessor: 'createdDateTime',
        minWidth: 150,
        cell: (cell: any) => (
          <DataTable.Cell>{formatDate(new Date(cell.value).getTime(), { dateStyle: 'medium' })}</DataTable.Cell>
        ),
      },
      {
        id: 'purpose',
        header: 'Use of funds',
        accessor: 'purpose',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { isLoading, data, error, refetch } = useProjectsByTenantId(tenantId);
  if (error) {
    ShowErrorNotification('Error loading projects', error);
  }

  if (itemDetailsVisible?.refreshParent) {
    itemDetailsVisible.refreshParent = false;
    refetch();
  }
  useEffect(()=>{
    if(!isLoading && redirectUrl){
      redirectPage();
    }
  },[redirectUrl, isLoading]);

  useEffect(() => {
    // we would like to get the data only once when the tenantId changes from 0 to actual tenantId
    if (prevTenantId.current === 0) {
      prevTenantId.current = tenantId;
      refetch();
    }
  }, [tenantId, refetch]);

  const filteredData = useMemo(() => {
    return searchQuery.trim().length !== 0
      ? data?.filter(
        (item: { purpose: string; projectName: string }) =>
          item.purpose.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.projectName.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      : data;
  }, [searchQuery, data]);
  const DeleteProjectSubmit = async (projectDetailsDelete: ItemInfo) => {
    try {
      setLoading(true);
      if (projectDetailsDelete.id !== undefined) {
        const data = await ProjectService.deleteProjectById(projectDetailsDelete?.id, tenantId);
        ShowSuccessNotification(`${projectDetailsDelete.name} deleted successfully`)
        refetch();
      }

    }
    catch (err) {
      ShowErrorNotification(`Error occurred while deleting: ${projectDetailsDelete.name}`);
    }
    finally {
      setLoading(false);

    }
  }
  const handleYesClick = () => {
    if (projectDetailsDelete) {
      DeleteProjectSubmit(projectDetailsDelete);
    }

    setProjectDetailsDelete({});
  };

  const handleNoClick = () => {
    setProjectDetailsDelete({});
  };

  return (
    isAppLoading ? <> <LoadingStateComponent loading={isAppLoading} /></> :
      <>

        <TitleBar>
          <TitleBar.Prefix>
            <Page.PanelControlButton target='sidebar' />
          </TitleBar.Prefix>
          <TitleBar.Title>{configRoutes[0].friendlyName}</TitleBar.Title>
          <TitleBar.Subtitle>{configRoutes[0].desc} </TitleBar.Subtitle>
        </TitleBar>
        <LoadingStateComponent loading={loading} />
        <Flex flexDirection='column' margin={8} padding={0} gap={8}>
          <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginBottom={16} marginTop={8}>
            <FilterBar onFilterChange={() => { }}>
              <FilterBar.Item name={'search'} label={''}>
                <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
              </FilterBar.Item>
              <FilterBar.Item name={'selectedType'} label={''}>
                <Button></Button>
              </FilterBar.Item>
            </FilterBar>

            <Button
              onClick={() => {
                setItemDetailsVisible({ visibility: true });
              }}
              variant='accent'
              color='primary'
              style={{ margin: '0px 0px 0px auto' }}
            >
              <Button.Prefix>
                <PlusIcon />
              </Button.Prefix>
              Project
            </Button>
          </Flex>
        </Flex>
        <Flex flexDirection='column' margin={8} padding={0} gap={8}>
          <Flex flexDirection={'column'}>
            <LoadingStateComponent loading={isLoading || tenantId === 0} />

            <DataTable
              columns={columns}
              data={filteredData ?? []}
              sortable
              loading={isLoading}
              variant={{ rowDensity: 'default', rowSeparation: 'zebraStripes' }}
              sortBy={{ id: 'projectName', desc: false }}
            >
              <DataTable.UserActions>
                <DataTable.RowActions>
                  {(row: any) => {
                    return (
                      <TableRowActions.Group>
                        <TableRowActions.Item
                          onClick={() => {
                            const project: Project = row.rows[row.currentRowIndex].original;
                            setItemDetailsVisible({
                              id: project.projectId,
                              index: row.currentRowIndex,
                              name: project.projectName,
                              visibility: true,
                            });
                          }}
                          prefixIcon={<EditIcon />}
                        />
                        {user?.roles !== "Staff" &&
                          <TableRowActions.Item
                            onClick={() => {
                              const project: Project = row.rows[row.currentRowIndex].original;
                              setProjectDetailsDelete({
                                id: project.projectId,
                                index: row.currentRowIndex,
                                name: project.projectName,
                                visibility: true,
                              });
                            }}
                            prefixIcon={<DeleteIcon />}
                          />
                        }

                      </TableRowActions.Group>
                    );
                  }}
                </DataTable.RowActions>
              </DataTable.UserActions>
            </DataTable>
          </Flex>
          {itemDetailsVisible && itemDetailsVisible.visibility && (
            <ProjectSheet
              closeDetails={(itemInfo?: ItemInfo) => {
                //reload the page as needed
                setItemDetailsVisible(itemInfo);
              }}
              show
              itemInfo={itemDetailsVisible}
            />
          )}

          <Modal title={`Delete project '${projectDetailsDelete?.name}'`} show={projectDetailsDelete?.visibility} onDismiss={handleNoClick} size={'small'}>
            <Text>Are you sure you want to delete this project?</Text>
            <br/><br/>
            <Text style={{color: 'red'}}>Warning!!</Text>
            <br/> 
            <Text>All assets including files belonging to this project will be deleted.  </Text>
            <br/>
            <Text> Once deleted, it cannot be recovered.</Text>
            <br/><br/>
            <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
              <Button width='80px' variant='default' onClick={handleNoClick}>
                Cancel
              </Button>
              <Button width='80px' variant='accent' color='primary' onClick={handleYesClick}>
                Confirm
              </Button>
            </Flex>

          </Modal>
        </Flex>
      </>
  );
};
