import React, { useEffect, useRef, useState } from 'react';
import { Modal, FormFieldMessages, Tabs, Tab, Sheet, TitleBar, TextInput, Label, FormField, TextArea, DataTable, ColumnType } from '@dynatrace/strato-components-preview';
import { Button, Flex, Text } from '@dynatrace/strato-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useForm, useWatch, useFieldArray, Controller } from 'react-hook-form';
import { RequestIcon, MailIcon, SourceIcon, CriticalIcon, DeleteIcon, ContainerIcon } from '@dynatrace/strato-icons';
import { ProjectService } from '../../../services/ProjectService';
import { ProjectDetail, ProjectPeopleRelation } from '../../../types/Project';
import { RequestDetails } from '../../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification, ShowValidationBanner } from '../../../utils/Notifications';
import { LoadingStateComponent } from '../../../components/LoadingStateComponent';
import { ItemInfo } from '../../../types/ListItemInfo';
import { useAppInfo } from '../../../contexts/AppContext';
import { TemplateService } from '../../../services/TemplateService';
import styled from 'styled-components';
import { PackageMessage } from './PackageMessage';
import { PackageSummary } from './PackageSummary';
import { PackageRecipients } from './PackageRecipients';
import { useProjectRequestById, useProjectWithDetailsID, useRequestTemplates } from '../../../hooks/use-minerva-data';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0 8px;
`;
export interface CreateRequestProps {
  peopleList: ProjectPeopleRelation[];
  projectDetails: ProjectDetail | any;
  isOpen: boolean;
  onClose: () => void;
  onPackageCreated: () => void;
  editPackageDetails?: any;
  projectPackages? : any;
  setEditProjectRequest? : any;
}

const CreatePackage = (props: CreateRequestProps) => {
  const { isOpen, onClose, peopleList, projectDetails, onPackageCreated, editPackageDetails, projectPackages, setEditProjectRequest } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [selectedUsers, setSelectedUsers] = useState<any[]>();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [sendConfirmModal, setSendConfirmModal] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<ProjectPeopleRelation[]>(peopleList);
  const [projectRequestId, setProjectRequestId] = useState(0);
  const [editRequestFlag, setEditRequestFlag] = useState(true);
  const projectRequests = useRef<RequestDetails[]>([]);
  const [selectedFilesRows, setSelectedFilesRows] = useState([]);
  // use refs for hooks
  const [activeTab, setActiveTab] = useState('Package');
  const [selectedFiles, setSelectedFiles] = useState<File[][]>([[]]); // Array of file arrays for each section
  const fileInputRefs = useRef<HTMLInputElement[]>([]); // Array of file input refs
  const [disableReqeustMessage, setDisableReqeustMessage] = useState(false);
  const {
    tenantId,
    documentTypes,
    tenantRequestReminders: reminderListData,
    tenantRequestStatusList: requestStatusList,
    user,
  } = useAppInfo();
  const { ProjectId } = useParams();
  const {
    isLoading: projectRequestsLoading,
    isRefetching: projectRequestsRefetching,
    data: projectRequestsData,
    error: requestError,
    refetch: refetchProjectRequests,
  } = useProjectRequestById(Number(ProjectId), tenantId);

  const loadingDetails =
    projectRequestsLoading || projectRequestsRefetching || loading;

  if (!loadingDetails) {
    //notes
    projectRequests.current = projectRequestsData?.projectRequest;
  }

  const otherDocumentTypeId = documentTypes?.find(
    (docType) => docType.documentTypeId === -1 && docType.tenantId === tenantId,
  )?.documentTypeAutoId;
  const {
    isLoading: isTemplatesLoading,
    data: requestTemplateData,
    refetch: refetchRequestTemplates,
    isRefetching,
  } = useRequestTemplates(tenantId);
  const defaultRequestStatusId = requestStatusList?.find((status) => status.statusName === 'New')?.statusAutoId;
  // let distinctSentToEmails = [];
  // if( editRequestDetails){
  //   distinctSentToEmails = Array.from(
  //   new Set(editRequestDetails.projectRequestSentList.map((item: any) => item.sentTo))
  //   );
  // }

  // setSelectedReceipents(distinctSentToEmails);
  useEffect(() => {
    if (peopleList.length > 0) {
      setRecipients(peopleList);

    }
  }, [peopleList]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<{
    projectId: number;
    tenantId: number;
    packageId: number;
    packageName: string;
    packageMessage: string;
    packageDescription: string;
    requestUpdateComments: string;
    requestDetails: [],
    requestPackages: any;
  }>({
    reValidateMode: 'onChange',
    defaultValues: {
    },
    mode: undefined,
  });

  let createRequestErrors = errors;
  const formValues = useWatch({
    control,
  });

  const updatedFields = useWatch({
    control,
    name: 'requestDetails',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requestDetails',
  });
  const [formData, setFormData] = useState({
    template: 0,
    packageId: 0,
    packageName: '',
    packageMessage: '',
    packageDescription: '',
    requestUpdateComments: '',
    requestPackages: [],

    reminder: '',
    recipients: [
      {
        email: '',
        firstName: '',
        lastName: '',
        peopleId: 0,
        packageShareId: 0,
      },
    ],
  });

  // UseEffect to update form values when externalData changes


  const updateProjectRequest = (editPackageDetails: any) => {

    reset({
      packageId: editPackageDetails.packageId,
      packageName:  editPackageDetails.packageName,
      packageMessage: editPackageDetails.packageMessage,
      packageDescription: editPackageDetails.packageDescription,
      requestPackages: selectedFilesRows,
    });

    setProjectRequestId(editPackageDetails.packageId);
    editPackageDetails.assignedTo.forEach((details: any, index: number) => {
      // Update instructions if they exist
      if (details.instructions) {
        //console.log(`Updating instructions for index ${index}:`, details.instructions);
        // Handle the logic to update the instruction in your UI here
      }

      if(index === 0) {
        if (details.requestPackages && details.requestPackages.length > 0) {
          const attachedFiles = details.requestPackages.map((file: any) => ({
            downloadFilePath: file.uniqueLink          ,
            // size: findSize(file.fileData),
            // type: file.type,
            // base64String: file.fileData,
            projectRequestId: file.projectRequestId,
            label: file.label,
            projectRequestSentId: file.projectRequestSentId,
            projectRequestName: file.projectRequestName,
            updateDate: file.updateDate,
            assignedto: file.createdBy,
            createdOn: file.createdOn,
          }));
          setSelectedFilesRows(attachedFiles);
        }
        else {
          setSelectedFilesRows([]);
        }
      }
      // Check if attachedFiles exist
      
    });

    const distinctSentToEmails = Array.from(
      new Set(editPackageDetails.assignedTo.map((item: any) => item.peopleId))
    );
    const distinctSentToEmailsList = editPackageDetails.assignedTo.map((item: any) =>
      ({ 'email': item.sentToEmailAddress, 'peopleId': item.peopleId, 'userName': item.sentToUserName, 'packageShareId': item.packageShareId }));

    const indexMap: any = distinctSentToEmails.reduce((acc: any, peopleId) => {
      const personIndex = peopleList.findIndex(person => person.peopleId === peopleId);
      if (personIndex !== -1) {
        // We use personIndex as string here to match the Record<string, boolean> type
        acc[personIndex.toString()] = true;
      }
      return acc;
    }, {} as Record<string, boolean>);
    // setSelectedReceipents(indexMap);
    setSelectedRows(indexMap);

    const updatedRecipients = peopleList
      ?.filter((row) => distinctSentToEmailsList.some((dist: any) => dist.peopleId === row.peopleId))
      .map((newRow) => {
        const existingRecipient = distinctSentToEmailsList.find((dist: any) => dist.peopleId === newRow.peopleId);
        return {
          email: newRow.email,
          firstName: newRow.firstName,
          lastName: newRow.lastName,
          // status: existingRecipient?.statusAutoId,
          peopleId: newRow.peopleId,
          packageShareId: existingRecipient.packageShareId,
          // uniqueLink: existingRecipient?.uniqueLink,
          // projectRequestSendToId: existingRecipient?.projectRequestSendToId,
        };
      });

    
    setSelectedUsers(updatedRecipients);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients ?? [],
    }));

    // const pendingRequestsDisable = editRequestDetails.projectRequestSentList.some((item: any) => item.statusName !== 'New');
    // setDisableReqeustMessage(pendingRequestsDisable);
  }

  useEffect(() => {
    if (isOpen) {
      reset({
        packageId:0,
        packageName: '',
        packageMessage: '',
        packageDescription: '',
        requestPackages: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset]);

  const findSize = (base64: string) => {
    // Calculate the padding
    const padding = (base64.match(/=*$/) || [])[0]?.length;

    // Calculate the size in bytes
    const fileSizeInBytes = (base64.length * 3) / 4 - (padding ? padding : 0);
    return fileSizeInBytes;
  };

  // updates project people relations post creating a new one or deleting
  const updateProjectPeopleRelations = async (addPersonsVisible: ItemInfo) => {
    try {
      setLoading(true);
      const data = await ProjectService.getProjectWithDetailsID<ProjectDetail>(addPersonsVisible?.id ?? 0, tenantId);
      setRecipients(data.projectPeopleRelation);
    } catch (error) {
      ShowErrorNotification('Error updating project people relation', error);
    } finally {
      // setLoadingPeopleRelations(false);
      setLoading(false);
    }
  };

  const handleRecipientsChange = (
    selectedRows: Record<string, boolean>,
    selectedRowsData?: any[],
    trigger?: 'user' | 'internal',
  ) => {
    setSelectedRows(selectedRows);

    // Map the selected indices to the actual row data
    const updatedRecipients = selectedRowsData?.map((row) => ({
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      peopleId: row.peopleId,
      packageShareId: row.packageShareId
    }));
    setSelectedUsers(selectedRowsData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients ?? [],
    }));
    setValidationErrors({});
  };

  console.log(validationErrors)

  const validateCreateRequestTab = (values: any) => {
    const errors: { [key: string]: string } = {};
    if (!selectedFilesRows || selectedFilesRows.length === 0) {
      errors['Files'] = 'Error';
    }  
    return errors;
  };

  const validateAddRecipientsTab = () => {
    const errors: { [key: string]: string } = {};
    if (formData?.recipients.length === 0 || formData?.recipients[0].email === '') {
      errors['recipients'] = 'Atleast one recipient must be selected';
    }

    return errors;
  };

  const [tabOptions, setTabOptions] = useState({
    'Package': false,
    'Recipients': true,
    'Package overview': true,
  });

  const handleCreateRequestNextButtonClick = (values: any) => {
    if (activeTab === 'Package') {
      const errors = validateCreateRequestTab(values);
      console.log(errors)
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };
  const handleNextButtonClick = () => {
    if (activeTab === 'Recipients') {
      const errors = validateAddRecipientsTab();
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };

  const enableNextTab = () => {
    const nextIndex = (currentTabIndex + 1) % tabs.length;
    setTabOptions((prevOptions) => ({
      ...prevOptions,
      [tabs[nextIndex]]: false,
    }));
    setCurrentTabIndex(nextIndex);
    setActiveTab(tabs[nextIndex]);
  };
  const disablePrevTab = () => {
    // const nextIndex = currentTabIndex % tabs.length;
    const prevIndex = (currentTabIndex - 1) % tabs.length;
    setCurrentTabIndex(prevIndex);
    setActiveTab(tabs[prevIndex]);
  };
  // const [reminderList, setReminderList] = useState<ReminderDetails[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabs = ['Package', 'Recipients', 'Package overview'];


  // const getDocumentTypeName = (documentTypeAutoId: any) => {
  //   return documentTypes.current.find((docType) => docType.documentTypeAutoId === documentTypeAutoId)?.documentTypeName;
  // };

  // save the information
  const saveDetails = async (values: any) => {
    const { template, packageName, packageMessage, packageDescription, requestTemplateName, requestTemplateDescription, requestPackages } = values;

    setFormData((prevData) => ({
      ...prevData,
      packageId: projectRequestId,
      packageName: packageName || prevData.packageName,
      packageMessage: packageMessage || prevData.packageMessage,
      packageDescription: packageDescription || prevData.packageDescription,
      requestPackages: selectedFilesRows || prevData.requestPackages,
    }));
    handleCreateRequestNextButtonClick(values);
  };

  const handleDismiss = () => {
    // Resetting additional form data state if needed
    setFormData({
      template: 0,
      packageId: 0,
      packageName: '',
      packageMessage: '',
      packageDescription: '',
      requestUpdateComments: '',
      requestPackages: [],
      reminder: '0',
      recipients: [
        {
          email: '',
          firstName: '',
          lastName: '',
          peopleId: 0,
          packageShareId:0,
        },
      ],
    });
    reset({
      packageName: '',
      packageMessage: '',
      packageDescription: '',
      requestPackages: [],
    });
    fileInputRefs.current = [];
    setSelectedRows({});
    setSelectedFilesRows([]);
    // Reset the active tab to the first tab
    setCurrentTabIndex(0);
    setActiveTab('Package');
    setValidationErrors({});
    // Optionally, reset tab options if needed
    setTabOptions({
      'Package': false,
      'Recipients': true,
      'Package overview': true,
    });
    setSelectedFiles([[]]);
    setEditRequestFlag(true);
    setEditProjectRequest({});
    onClose();
  };

  // Function to handle tab change
  const handleTabChange = (index: number) => {
    // Implement any logic needed before switching tabs, such as validation
    const isValid = true;
    if (isValid) {
      setCurrentTabIndex(index);
      setTabOptions((prevOptions) => ({
        ...prevOptions,
        [Object.keys(tabOptions)[index]]: false,
      }));
      const tabKeys = Object.keys(tabOptions);
      const tabKeyToIndex = tabKeys.reduce((acc, key, index) => {
        acc[index] = key;
        return acc;
      }, {} as { [key: number]: string });
      setActiveTab(tabKeyToIndex[index]);
    } else {
      // Handle case when validation fails
      // console.warn('Validation failed, cannot change tab.');
    }
  };

  const handleSend = handleSubmit((values) => {
    // Perform validations
    const CreateRequestErrors = validateCreateRequestTab(values);
    const ReceipentsErrors = validateAddRecipientsTab();

    // Merge both errors
    const combinedErrors = { ...CreateRequestErrors, ...ReceipentsErrors };

    // If there are any errors, set them in validationErrors
    if (Object.keys(combinedErrors).length !== 0) {
      setValidationErrors(combinedErrors);
    } else {
      // If there are no errors, clear the validationErrors
      setValidationErrors({});
      // Show the send confirmation modal
      setSendConfirmModal(true);
    }
  });

  // Function to handle the Yes click
  const handleYesClick = () => {
    CreateRequestSubmitClick(formData, projectDetails, documentTypes);
    setSendConfirmModal(false);
  };

  // Function to close the modal when No is clicked
  const handleNoClick = () => {
    setSendConfirmModal(false);
  };

  const CreateRequestSubmitClick = async (formData: any, projectDetails: any, documentTypes: any) => {
    let mapProjectRequest: any = {
      packageId: projectRequestId,
      packageName: '',
      packageDescription: '',
      reminderId: '',
      document: [],
      requestSendTo: [],
      tenantId: 0,
      projectId: 0,
      recipients:[],
      requestPackages:[],
    };
    mapProjectRequest.packageName = formData.packageName;
    mapProjectRequest.packageDescription = formData.packageDescription;
    mapProjectRequest.packageMessage = formData.packageMessage;
    mapProjectRequest.packageUpdateComments = formData.requestUpdateComments;
    mapProjectRequest.requestSendTo = [];    
    mapProjectRequest.projectId = projectDetails?.project.projectId;
    mapProjectRequest.tenantId = tenantId;
    mapProjectRequest.statusAutoId = defaultRequestStatusId;
    mapProjectRequest.reminderId = reminderListData?.[0].remindersAutoId;
    mapProjectRequest.requestPackages = selectedFilesRows;
    mapProjectRequest.recipients = formData.recipients;
    mapProjectRequest.recipients.forEach((recipient: { packageShareId: any; email: any; }) => {
      const matchedUser = selectedUsers?.find(user => user.email === recipient.email);
      if (matchedUser) {
          recipient.packageShareId = matchedUser.packageShareId;
      }
  });
    mapProjectRequest.createdBy = user?.userName;




    try {
      setLoading(true);
      if (projectRequestId !== 0) {

        const data: any = await ProjectService.updateProjectPackageWithDetails<Request>(mapProjectRequest, tenantId, mapProjectRequest.projectId);
        if (data.code === '500') {
          ShowErrorNotification('Error while updating Package', data);
        } else {
          ShowSuccessNotification('Package updated successfully');
          setEditRequestFlag(true);
          onPackageCreated();
        }
      }
      else {
        const data: any = await ProjectService.postPackageFilesWithDetails<Request>(mapProjectRequest, tenantId, mapProjectRequest.projectId);
        if (data.code === '500') {
          ShowErrorNotification('Error while creating Package', data);
        } else {
          ShowSuccessNotification('Package created successfully');
          onPackageCreated();
        }
      }
      handleDismiss();
    } catch (error) {
      ShowErrorNotification('Error while creating Package', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((fileList, i) => (i === index ? [...fileList, ...files] : fileList));
      return updatedFiles; // Return the updated state
    });
  };

  // Handle file removal
  const handleFileRemove = (fileToRemove: File, index: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.map((fileList, i) => (i === index ? fileList.filter((file: File) => file !== fileToRemove) : fileList)),
    );
  };

  const handleRemovePackage = (indexToRemove: number) => {
    setSelectedFilesRows(prevRows => prevRows.filter((_, i) => i !== indexToRemove));

    setFormData(prevData => ({
      ...prevData,
      requestPackages: prevData.requestPackages.filter((_: any, i: number) => i !== indexToRemove),
    }));
  };

  const handleAddPackage = (newData: any[]) => {
  
    setSelectedFilesRows((prevRows) => {
      const updatedRows = [...prevRows] as any;
  
      newData.forEach((newItem) => {
        const existingIndex = updatedRows.findIndex(
          (item: any) => item.projectRequestSentId === newItem.projectRequestSentId
        );
  
        if (existingIndex !== -1) {
          // Update the existing row's fields
          updatedRows[existingIndex] = {
            ...updatedRows[existingIndex],
            ...newItem,
          };
        } else {
          // Not found, so push it as a new entry
          updatedRows.push(newItem);
        }
      });
  
      return updatedRows;
    });
  
    setFormData((prevData: any) => ({
      ...prevData,
      requestPackages: [...newData],
    }));

  };
  


  // Define columns for the DataTable
  const columns: any = [
   {
         id: 'fileName',
         header: 'File Name',
         width: 200,
         accessor: 'fileName',
         columnType: 'string' as ColumnType,
         Cell: ({ row }: any) => (
           <DataTable.Cell>
             {row?.original?.downloadFilePath?.split('/')?.pop() || 'N/A'}
           </DataTable.Cell>
         ),
       },
       {
         id: 'fileType',
         header: 'File Type',
         accessor: 'fileType',
         columnType: 'string' as ColumnType,
         Cell: ({ row }: any) => (
           <DataTable.Cell>{row?.original?.downloadFilePath?.split('.')?.pop() || 'N/A'}</DataTable.Cell>
         ),
       },
       {
         id: 'createdby',
         header: 'Created by',
         accessor: 'createdby',
         width: 150,
         columnType: 'string' as ColumnType,
         Cell: ({ row }: any) => (
           <DataTable.Cell>{row.original.assignedto || 'N/A'}</DataTable.Cell>
         ),
       },
       {
         id: 'modifiedOn',
         header: 'Updated Date',
         accessor: 'createdOn',
         width: 200,
         Cell: ({ row }: any) => (
   <DataTable.Cell>{row?.original?.updateDate != null && row?.original?.updateDate != ""? format(parseISO(row?.original?.updateDate), 'MMM dd, yyyy hh:mm a') : row?.original?.createdOn != null && row?.original?.createdOn != "" ? format(parseISO(row?.original?.createdOn), 'MMM dd, yyyy hh:mm a') : 'N/A'}</DataTable.Cell>      ),
       },
       {
         id: 'source',
         header: 'Source',
         accessor: 'projectRequestName',
         width: 200,
         columnType: 'string' as ColumnType,
       },
       {
        id: 'actions',
        header: 'Actions',
        width: 100,
        Cell: ({ row }: any) => {
          // row.index gives the item index in the table data
          const rowIndex = row.index;
          return (
            <Button variant="default" onClick={() => handleRemovePackage(rowIndex)}>
              <DeleteIcon />
            </Button>
          );
        },
      },
  ];

  const removeFileSection = (sectionIndex: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      // Create a copy of `selectedFiles`
      const updatedFiles = [...prevSelectedFiles];

      // Remove the entire section's files based on `sectionIndex`
      updatedFiles.splice(sectionIndex, 1);

      // Return the updated array without the section's files
      return updatedFiles;
    });
  };

  return (
    <Sheet show={isOpen} onDismiss={handleDismiss}>
      <Modal title={editPackageDetails?.packageName ? 'Update package confirmation' : 'Send package confirmation'} show={sendConfirmModal} onDismiss={handleNoClick} size={'small'}>
        {editPackageDetails?.packageName  && (
          <Flex flexDirection='column' columnGap={16}>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Comments is required',
                },
                minLength: {
                  value: 1,
                  message: '',
                },
              }}
              name="requestUpdateComments"
              control={control}
              render={({ field, fieldState }) => (
                <FormField required>
                  <Label>Package update comments</Label>
                  <TextArea
                    {...field}
                    rows={3}
                    onChange={(e) => {
                      // Update the formData state directly on change
                      setFormData((prevState) => ({
                        ...prevState,
                        requestUpdateComments: e,
                      }));

                      // Also update the field value in the form
                      field.onChange(e);
                    }}
                    width={'100%'}
                    placeholder="Enter Package comments"
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}

                  />
                </FormField>
              )}
            />
          </Flex>
        )}


        <Text>You are about to send this package to all recipients.</Text>
        <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
          <Button width='80px' variant='default' onClick={handleNoClick}>
            Cancel
          </Button>
          <Button width='80px' variant='accent' color='primary' onClick={handleYesClick} disabled={editPackageDetails?.packageName && (formData.requestUpdateComments === '' || formData.requestUpdateComments === null)}>
            Confirm
          </Button>
        </Flex>

      </Modal>
      <LoadingStateComponent loading={loading || isTemplatesLoading || isRefetching} />
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <TitleBar>
          <TitleBar.Title> {editPackageDetails?.packageName ? "Edit '" + editPackageDetails.packageName + "'" : 'Create new package'} </TitleBar.Title>
          <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
            <Flex justifyContent='flex-end' gap={8}>
              <Button width='80px' variant='default' onClick={handleDismiss}>
                Cancel
              </Button>

              <Button width='80px' variant='emphasized' onClick={disablePrevTab} disabled={activeTab === 'Package'}>
                Previous
              </Button>
              {activeTab === 'Package' && (
                <Button width='80px' variant='emphasized' type='submit'>
                  Next
                </Button>
              )}
              {activeTab !== 'Package' && (
                <Button
                  width='80px'
                  variant='emphasized'
                  onClick={handleNextButtonClick}
                  disabled={activeTab === 'Package overview'}
                >
                  Next
                </Button>
              )}

              <Button
                width='80px'
                variant='accent'
                color='primary'
                onClick={() => handleSend()}
                disabled={tabOptions['Package overview'] === true}
              >
                Send
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>
        <Flex flexDirection='row' gap={8} alignItems='end' justifyContent='flex-end'></Flex>
        <Tabs selectedIndex={currentTabIndex} onChange={handleTabChange}>
          {loading ? (
            <LoadingStateComponent loading={true} />
          ) : (
            <Tab
              title={'Package'}
              prefixIcon={
                Object.keys(createRequestErrors).length !== 0 || validationErrors['Files'] ? (
                  <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
                ) : (
                  <ContainerIcon />
                )
              }
              disabled={tabOptions['Package']}
            >
              {validationErrors['Files'] ? (selectedFilesRows.length === 0 ? ShowValidationBanner(validationErrors['Files'], "Add atleast one file to proceed") : "" ): ""}
              <PackageMessage
                control={control}
                fields={fields}
                selectedFiles={selectedFiles}
                handleFileRemove={handleFileRemove}
                handleFileChange={handleFileChange}
                fileInputRefs={fileInputRefs}
                remove={remove}
                removeFileSection={removeFileSection}
                editPackageDetails={editPackageDetails}
                updateProjectRequest={updateProjectRequest}
                editRequestFlag={editRequestFlag}
                setEditRequestFlag={setEditRequestFlag}
                disableReqeustMessage={disableReqeustMessage}
                projectRequests={projectRequests.current}
                refetchProjectRequests={refetchProjectRequests}
                setLoading={setLoading}
                handleAddPackage={handleAddPackage}
                selectedFilesRows={selectedFilesRows}
                projectPackages= {projectPackages}
                validationErrors={validationErrors}
              />
              <DataTable
                data={selectedFilesRows}
                columns={columns}
              >
                <DataTable.EmptyState>No file added, add atleast one file to proceed.</DataTable.EmptyState>
              </DataTable>
            </Tab>
          )}

          <Tab
            title='Recipients'
            prefixIcon={
              validationErrors['recipients'] ? (
                <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
              ) : (
                <MailIcon />
              )
            }
            disabled={tabOptions['Recipients']}
          >
            <PackageRecipients
              projectDetails={projectDetails}
              peopleList={peopleList}
              recipients={recipients}
              handleSubmit={handleSubmit}
              validationErrors={validationErrors}
              selectedRows={selectedRows}
              updateProjectPeopleRelations={updateProjectPeopleRelations}
              handleRecipientsChange={handleRecipientsChange}
              updateProjectRequest={updateProjectRequest}
            //selectedReceipents={distinctSentToEmails}
            />
          </Tab>

          <Tab title='Package overview' prefixIcon={<SourceIcon />} disabled={tabOptions['Package overview']}>
            <PackageSummary formValues={formValues} formData={formData} selectedFiles={selectedFiles} />
          </Tab>
        </Tabs>
      </form>
      <FormFieldMessages />
    </Sheet>
  );
};

export default CreatePackage;